<div class="noMargins">
    <page-title>{{'Truck Inspections' | translate}}</page-title>
    <form #form #ngForm="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label *ngIf="airports.length > 1">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect name="query.AirportId" [(ngModel)]="query.AirportId">
                <multiselect-option *ngFor="let option of airports|orderBy:'ICAOCode'" [value]="option.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate" placeholder="select a start date" />
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate" />
        </label>
        <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="submit" [disabled]="!query.FromDate"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
        </label>
        <label class="inline" *ngIf="'DeicingDeleteRecords'|hasPermission">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="downloadSelectedForms()" [disabled]="!this.grid.gridApi || this.grid.gridApi.getSelectedRows().length === 0"><span class="mdi mdi-download"></span> {{'Download Forms'|translate}}</button>
        </label>
    </form>

    <grid #grid
          gridId="preferences.TruckInfo.ColumnExport"
          [query]="query"
          [resource]="api.TruckInfo"
          [exportResource]="api.TruckInfoExport"
          [columnDefs]="columnDefs"
          rowSelection="multiple">
    </grid>
</div>
