import {AfterViewInit, Component, Input, ViewContainerRef} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DialogService } from 'swx.front-end-lib';

@Component({
    template: `
        <a (click)="viewData(params)" class="mdi mdi-eye"></a>`
})
export class RawDataCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(
        private dialogService: DialogService,
        private viewContainerRef: ViewContainerRef,
    ) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    refresh(): boolean {
        return false;
    }

    viewData({ data }) {
        const rawData = data.RawData;

        this.dialogService.show(this.viewContainerRef, RawDataViewDialogComponent,
            {
                data: rawData,
                type: 'json',
            },
            {
                title: 'Raw Data',
                width: 700,
                height: 320,
                modal: true,
                dialogClass: 'flexDialog'
            });
    }
}

@Component({
    template: `<pre>{{ jsonData | json}}</pre>`
})
export class RawDataViewDialogComponent implements AfterViewInit {
    @Input() data: string;

    jsonData: any;

    ngAfterViewInit(): void {
        this.jsonData = JSON.parse(this.data);
    }
}
