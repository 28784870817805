import { Component } from '@angular/core';
import { AuthenticationService } from 'swx.front-end-lib';

@Component({
    selector: '[menu]',
    templateUrl: 'Menu.component.html'
})
export class MenuComponent {
    currentUser;

    constructor(
        private authentication: AuthenticationService,
    ) {
        this.authentication.getCurrentUser()
            .then(user => this.currentUser = user);

        this.authentication.userChange.subscribe(user => {
            this.currentUser = user;
        });
    }
}
