<div>
    <page-title>{{('Log in'|translate)}}</page-title>
    <h3 style="margin-top: 0;">{{'Please log in to continue'|translate}}</h3>
    <form class="form" #form="ngForm" (submit)="login()">
        <label>
            <span class="caption">{{'Username'|translate}}</span>
            <input type="text" [required]="password != null || loginAttempted" name="username" [(ngModel)]="username">
        </label>
        <label>
            <span class="caption">{{'Password'|translate}}</span>
            <input type="password" [required]="username != null || loginAttempted" name="password" [(ngModel)]="password"> <a class="mdi mdi-eye" style="padding: 6px; color: darkgrey;" onclick="$('[name=password]').attr('type', $('[name=password]').attr('type') === 'password' ? 'text' : 'password'); return false;" title="{{'Reveal'|translate}}"></a>
        </label>
        <a routerLink="/forgotPassword">{{'Forgot password?'}}</a>
        <br />
        <br />
        <input type="submit" [disabled]="!form.form.valid" value="{{'Log in'|translate}}" />
    </form>
</div>
 