<page-title>{{'User settings'|translate}}</page-title>
<div class="userProfile" style="padding: 20px 50px;">
    <form #preferencesForm="ngForm" (submit)="savePreferences()" *ngIf="'Coordinator'|hasPermission">
		<fieldset class="form">
			<h2>{{'Coordinator preferences'|translate}}</h2>
			<label>
				<span class="caption">{{'Use IATA code'|translate}}</span>
				<input type="checkbox" [(ngModel)]="currentUser.UseIataCode" name="currentUser.UseIataCode">
			</label>
			<label>
				<span class="caption">{{'Use local time'|translate}}</span>
				<input type="checkbox" [(ngModel)]="currentUser.UseLocalTime" name="currentUser.UseLocalTime">
			</label>
			<label>
				<span class="caption">{{'Use Fahrenheit'|translate}}</span>
				<input type="checkbox" [(ngModel)]="currentUser.UseFahrenheit" name="currentUser.UseFahrenheit">
			</label>
			<br />
			<label>
				<input type="submit" class="btn btn-info" [disabled]="!preferencesForm.form.valid" value="{{'Save'|translate}}" />
			</label>
		</fieldset>
	</form>
	<br />
	<br />
	<form #changePasswordForm="ngForm" (submit)="changePassword()">
		<fieldset class="form">
			<h2>{{'Update password'|translate}}</h2>
			<h4 style="margin-top: 0;">{{'Please enter your old and new password'|translate}}</h4>
			<label>
				<span class="caption">{{'Old password'|translate}}</span>
				<input type="password" required class="form-control" [(ngModel)]="currentPassword" name="currentPassword">
			</label>
			<label>
				<span class="caption">{{'New password'|translate}}</span>
				<input type="password" required class="form-control" [(ngModel)]="newPassword" name="newPassword">
			</label>
			<label>
				<span class="caption">{{'Repeat password'|translate}}</span>
				<input type="password" required class="form-control" [(ngModel)]="repeatPassword" name="repeatPassword" [password-match]="newPassword">
			</label>
			<br />
			<label>
				<input type="submit" class="btn btn-info" [disabled]="!changePasswordForm.form.valid" value="{{'Change password'|translate}}" />
			</label>
		</fieldset>
	</form>
</div>