import { Component } from '@angular/core';
import { ApiService } from '../shared/Api.service';

@Component({
    selector: 'login',
    templateUrl: 'ForgotPassword.component.html'
})
export class ForgotPasswordComponent {
    email: string;
    sent = false;
    error = false;

    constructor(
        private api: ApiService
    ) {
    }

    submit() {
        this.api.PasswordResetToken.post({
                Email: this.email
            })
            .then(result => {
                    if (result) {
                        this.sent = true;
                    } else {
                        this.error = true;
                    }
                },
                () => {
                    this.error = true;
                }
            );
    }
}