<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Deicing mobile configuration'|translate) + ': ' + (item?.Name || 'new')}}</page-title>
    <div id="deicingFluidEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab active" [class.invalid]="!ngForm.form.valid">{{'Basic info'}}</a>
            <label>
                <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            </label>
            <label>
                <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            </label>
            <label>
                <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
            </label>
        </div>

        <div class="form">
            <fieldset class="contentMargins" [disabled]="!('ConfigEditDeicingMobileConfigurations'|hasPermission)">
                <label>
                    <span class="caption">{{'Name'|translate}}</span>
                    <input type="text" required [(ngModel)]="item.Name" name="Name" />
                </label>
                <label>
                    <span class="caption">{{'Active'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
                </label>
                <label>
                    <span class="caption">{{'Truck certification text'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileRequirementCertificationText" name="MobileRequirementCertificationText" [style.width.ch]="(item.MobileRequirementCertificationText || '').length || 15" />
                </label>
                <label>
                    <span class="caption">{{'Show truck certification signature panel'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileRequirementCertificationSignaturePanel" name="MobileRequirementCertificationSignaturePanel" />
                </label>
                <label>
                    <span class="caption">{{'Deicing record certification text'|translate}}</span>
                    <input type="text" [(ngModel)]="item.MobileShiftCertificationText" name="MobileShiftCertificationText" [style.width.ch]="(item.MobileShiftCertificationText || '').length || 15" />
                </label>
                <label>
                    <span class="caption">{{'Show deicing record certification signature panel'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MobileShiftCertificationSignaturePanel" name="MobileShiftCertificationSignaturePanel" />
                </label>
                <br />
                <label class="inline">
                    <input type="button" *ngIf="('ConfigEditDeicingMobileConfigurations'|hasPermission)" (click)="addVehicleItem();" value="{{'Add vehicle item'|translate}}" />
                </label><br /><br />
                <table class="table" *ngIf="item.DeicingMobileFields">
                    <tr>
                        <th>{{'Id'|translate}}</th>
                        <th>{{'Name'|translate}}</th>
                        <th>{{'Order'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let vehicleItem of item.DeicingMobileFields?.Items; let i=index">
                        <td>
                            <input type="text" [(ngModel)]="vehicleItem.Id" name="vehicleItem.Id[{{i}}]" required size="5" />
                        </td>
                        <td>
                            <input type="text" [style.width]="getMaxVehicleItemWidth()" [(ngModel)]="vehicleItem.Name" name="vehicleItem.Name[{{i}}]" required />
                        </td>
                        <td>
                            <input type="number" [(ngModel)]="vehicleItem.Order" name="vehicleItem.Order[{{i}}]" style="width: 50px;" />
                        </td>
                        <td>
                            <a *ngIf="('ConfigEditDeicingMobileConfigurations'|hasPermission)" (click)="removeVehicleItem(vehicleItem);" class="mdi mdi-delete"></a>
                        </td>
                    </tr>
                </table><br />
                <label class="inline">
                    <input type="button" *ngIf="('ConfigEditDeicingMobileConfigurations'|hasPermission)" (click)="addVehicleStatus();" value="{{'Add vehicle status'|translate}}" />
                </label><br /><br />
                <table class="table" *ngIf="item.DeicingMobileFields">
                    <tr>
                        <th>{{'Id'|translate}}</th>
                        <th>{{'Name'|translate}}</th>
                        <th>{{'Order'|translate}}</th>
                        <th>{{'Remark required ?'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let vehicleStatus of item.DeicingMobileFields?.Statuses; let i=index">
                        <td>
                            <input type="text" [(ngModel)]="vehicleStatus.Id" name="vehicleStatus.Id[{{i}}]" required size="5" />
                        </td>
                        <td>
                            <input type="text" [style.width]="getMaxVehicleItemWidth()" [(ngModel)]="vehicleStatus.Name" name="vehicleStatus.Name[{{i}}]" required />
                        </td>
                        <td>
                            <input type="number" [(ngModel)]="vehicleStatus.Order" name="vehicleStatus.Order[{{i}}]" style="width: 50px;" />
                        </td>
                        <td>
                            <input type="checkbox" [(ngModel)]="vehicleStatus.RemarkRequired" name="vehicleStatus.RemarkRequired[{{i}}]" />
                        </td>
                        <td>
                            <a *ngIf="('ConfigEditDeicingMobileConfigurations'|hasPermission)" (click)="removeVehicleStatus(vehicleStatus);" class="mdi mdi-delete"></a>
                        </td>
                    </tr>
                </table>

            </fieldset>

        </div>

    </div>
</form>
