<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Deicing fluid'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="deicingFluidEditPage" class="noMargins">
        <div class="toolbar">
	        <a class="tab active" [class.invalid]="!ngForm.form.valid">{{'Basic info'}}</a>
            <label>
                <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            </label>
            <label>
                <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            </label>
            <label>
                <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
            </label>
        </div>
		
	    <div class="form">
		    <fieldset class="contentMargins" [disabled]="!('ConfigEditDeicingFluids'|hasPermission)">
	            <label>
	                <span class="caption">{{'Name'|translate}}</span>
	                <input type="text" required [(ngModel)]="item.Name" name="Name" />
	            </label>
			    <label>
				    <span class="caption">{{'Fluid type'|translate}}</span>
				    <select [(ngModel)]="item.Type" name="item.Type" required [selectedIndex]="-1">
						<option *ngFor="let option of fluidTypes|keys" [ngValue]="option">
							{{fluidTypes[option]}}
						</option>
				    </select>
			    </label>
	            <label>
	                <span class="caption">{{'Active'|translate}}</span>
	                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
	            </label>
	        </fieldset>
	    </div>
    </div>
</form>