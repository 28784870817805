<div id="serviceProviderList" class="noMargins">
	<page-title>{{('Service providers'|translate)}}</page-title>
	<div class="toolbar gridFilters">
		<form #form #ngForm="ngForm" (submit)="refresh()">
			<label class="inline">
				<span class="caption">{{'Search'|translate}}</span>
				<input [(ngModel)]="grid.searchQuery" name="searchQuery" />
			</label>
			<label class="inline">
				<input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
				<span class="caption">{{'Include inactive'|translate}}</span>
			</label>
			<label>
				<a *ngIf="'ConfigEditServiceProviders'|hasPermission" routerLink="/serviceProviders/new"><button type="button">{{'Add service provider'|translate}}</button></a>
			</label>
			<filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
			<label class="inline">
				<span class="caption">&nbsp;</span>
				<button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
			</label>
			<label class="inline">
				<span class="caption">&nbsp;</span>
				<button type="submit" [disabled]="!ngForm.form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
			</label>
            <label class="inline">
                <span class="caption">&nbsp;</span>
                <button type="button" [disabled]="!ngForm.form.valid" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
            </label>
		</form>
	</div>

    <config-grid #grid
          gridId="preferences.ServiceProvider.ColumnExport"
          [query]="query"
          [resource]="api.ServiceProvider"
          [exportResource]="api.ServiceProviderExport"
          [columnDefs]="columnDefs">
    </config-grid>
</div>
