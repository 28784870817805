import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService, TranslateService, NotificationService, ConfirmService, FilterPipe, DialogService, GridComponent } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import { ServiceProviderSelectionDialogComponent } from '../user/UserDetail.component';

@Component({
    templateUrl: 'AirlineDetail.component.html'
})
export class AirlineDetailComponent implements OnInit {
    item;
    returnPath;
    tab;
    @ViewChild('ngForm', { static: true }) ngForm;
    airplaneSearchQuery;
    orderByFieldAirplanes;
    orderByReverseAirplanes;
    tailNumber;
    registrationPrefixes = new Array();
    serviceProviders = this.api.ServiceProvider.query({ SkipPermissions: true });
    currentRegistrationPrefix;
    clients = this.api.Client.query();
    serviceProvider;
    serviceProviderSearchQuery;
    serviceProvidersColumnDefs = null;
    @ViewChild('serviceProvidersGrid', { static: false }) serviceProvidersGrid: GridComponent;
    exportDeicingAirlineDeicingServiceProvidersResource = this.api.DeicingAirlineDeicingServiceProviderExport;
    exportDeicingAirlineDeicingServiceProvidersQuery: any = {};

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private translateService: TranslateService,
        private notification: NotificationService,
        private changes: ChangesService,
        private confirmService: ConfirmService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private hasPermissionService: HasPermissionService,
        private dialogService: DialogService,
    ) {
    }

    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        this.exportDeicingAirlineDeicingServiceProvidersQuery = {
	        DeicingAirlineId: id,
        };

        if (copyId) {
            this.item = this.api.Airline.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.DeicingServiceProviderAirlines) {
                    this.item.DeicingServiceProviderAirlines.forEach(related => {
                        delete related.AirlineId;
                    });
                }

                if (this.item.Airplanes) {
                    this.item.Airplanes.forEach(related => {
                        delete related.AirlineId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.Airline.create({
                Active: true
            });
        } else {
            this.item = this.api.Airline.get({ id: id });
        }

        Promise.all([
            this.item.$promise,
            this.serviceProviders.$promise
        ]).then(() => {
            if (this.item.AircraftIdentificationPrefix) {
                this.registrationPrefixes = this.item.AircraftIdentificationPrefix ? this.item.AircraftIdentificationPrefix.split(",") : [];
                this.currentRegistrationPrefix = this.registrationPrefixes[0];
            }

            this.serviceProvidersColumnDefs = [
                { "colId": "ServiceProviderId", "field": "ServiceProviderId", "headerName": "#", "width": 60, "filterType": "integer" },
                { "colId": "Name", "field": "Name", "headerName": "Name", "width": 300, "cellRenderer": c => {
                    if (c.data == null) return '';

                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigViewServiceProviders')) {
                        container.innerHTML += '<a href="/serviceProviders/' + c.data.ServiceProviderId + '" onclick="event.preventDefault();">' + c.value + '</a>';
                        container.querySelector('a').addEventListener('click',
                            e => {
                                if (this.ngForm.form.pristine) {
                                    this.router.navigateByUrl(e.target['getAttribute']('href'));
                                } else {
                                    this.confirmService.confirm(this.translateService.translate('Are you sure you want to discard your changes?')).then(() => {
                                        this.router.navigateByUrl(e.target['getAttribute']('href'));
                                    }).catch(() => {});
                                }
                            });
                    } else {
                        container.innerHTML = c.value;
                    }
                    return container;
                } },
                { "colId": "Code", "field": "Code", "headerName": "Upload mapping code", "width": 120 },
                { "colId": "AirportCode", "field": "AirportCode", "headerName": "Airport", "width": 150 },
	            { "colId": "_spacer", "flex": 1, "excelIgnore": true },
                { "colId": "Actions", "field": "ServiceProviderId", "headerName": "", "width": 50, "cellClass": "actions", "cellRenderer": c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigEditAirlineServiceProviders')) {
                        container.innerHTML += '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>';
                        container.querySelector('.mdi-delete').addEventListener('click', () => this.removeServiceProviderAirline(c.data).then(() => c.api.setRowData(this.transformDeicingAirlineServiceProviders(this.item.DeicingServiceProviderAirlines))));
                    }
                    return container;
                }, "pinned": "right", "excelIgnore": true },
            ];

            setTimeout(() => {
                this.ngForm.form.controls.airplanesForm.updateValueAndValidity();
                this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
            });
        });

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }

    transformDeicingAirlineServiceProviders(items) {
        if (items == null) return items;
        items.forEach(x => {
            var serviceProvider = this.serviceProviders.find(a => a.Id === x.ServiceProviderId) || {};
            x.Name = serviceProvider.Name;
            x.Code = serviceProvider.Code;
            x.AirportCode = serviceProvider.AirportCode;
        });

        return items;
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['Airline'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };
    
    logoChanged() {
        var img = new Image();
        img.src = this.item.Logo;
        var maxWidth = 215;
        var maxHeight = 50;

        img.onload = () => {
            if (img.width > maxWidth || img.height > maxHeight) {
                this.notification.show(this.translateService.translate('Logo size error (' + img.width + ' / ' + img.height + ') : Maximum allowed (in pixels) is ' + maxWidth + ' (width) and ' + maxHeight + ' (height)'), { type: 'error' });
            }
        };
    }

    addAirplane(tailNumber) {
        this.item.Airplanes = this.item.Airplanes || [];
        this.item.Airplanes.push({
            TailNumber: (this.item.AircraftIdentificationUneditablePrefix ? this.currentRegistrationPrefix.concat(tailNumber) : tailNumber)
        });
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removeAirplane(airplane) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.Airplanes.splice(this.item.Airplanes.indexOf(airplane), 1);
            this.ngForm.form.updateValueAndValidity();
            this.ngForm.form.markAsDirty();
        }).catch(() => {});
    }

    showServiceProvidersPopup() {
        this.dialogService.show(this.viewContainerRef, ServiceProviderSelectionDialogComponent,
            {
                options: this.serviceProvidersNotEnabled(this.serviceProviders, this.item.DeicingServiceProviderAirlines),
                close: selectedIds => this.onCloseServiceProviderPopup(selectedIds)
            },
            {
                title: 'Select service providers',
                width: '80%',
                height: window.innerHeight * 0.8,
                modal: true,
                dialogClass: 'flexDialog'
            });
    }

    private onCloseServiceProviderPopup(selectedIds: number[]) {
        this.item.DeicingServiceProviderAirlines = this.item.DeicingServiceProviderAirlines || [];
        selectedIds.forEach(id => this.item.DeicingServiceProviderAirlines.push({
            ServiceProviderId: id
        }));
        this.serviceProvidersGrid.gridApi.setRowData(this.transformDeicingAirlineServiceProviders(this.item.DeicingServiceProviderAirlines));
        this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
        this.ngForm.form.controls.serviceProvidersForm.markAsDirty();
    }

    removeServiceProviderAirline(serviceProvider) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingServiceProviderAirlines.splice(this.item.DeicingServiceProviderAirlines.indexOf(serviceProvider), 1);
            this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
            this.ngForm.form.controls.serviceProvidersForm.markAsDirty();
        }).catch(() => {});
    }

    serviceProvidersNotEnabled(serviceProviders: any[], serviceProviderAirlines: any[]) {
        if (!serviceProviders || !serviceProviderAirlines) return serviceProviders;

        return serviceProviders.filter(a => serviceProviderAirlines.every(r => r.ServiceProviderId !== a.Id as any));
    }

    searchFilter(items, expression, searchType) {
        if (typeof items === 'undefined' || items === null || typeof items.length === 'undefined' || items.length === 0)
            return [];

        if (!expression) {
            return items;
        }

        function isFound(search: any, value: any) {
            return value.toUpperCase().indexOf(search.toUpperCase()) > -1;
        }

        switch (searchType) {
            case 'airplanes':
                return items.filter(item => {
                    return isFound(expression, item.TailNumber);
                });
            case 'serviceProviderAirline':
                return items.filter(item => {
                    var related = this.serviceProviders.filter(val => (val.Id === item.ServiceProviderId))[0];
                    var relatedName = related.Name;
                    return isFound(expression, relatedName);
                });
            default:
                return items;
        }
    }

    onRegistrationPrefixChange() {
        this.registrationPrefixes = this.item.AircraftIdentificationPrefix ? this.item.AircraftIdentificationPrefix.split(",") : [];
        if (!this.item.AircraftIdentificationUneditablePrefix && this.currentRegistrationPrefix) {
            this.tailNumber = this.currentRegistrationPrefix;
        }
    }

    checkRegistrationFormat(event: any) {
        if (this.registrationPrefixes.length === 0) return;
        const prefix = this.registrationPrefixes.find((prefix) => (event.target.value || "").startsWith(prefix.trim()));

        if (prefix) {
            this.currentRegistrationPrefix = prefix;
            if (this.item.AircraftIdentificationUneditablePrefix) {
                this.tailNumber = this.tailNumber.substring(prefix.length, this.tailNumber.length);
            }
        } 
    }

    onRegistrationClick(event : any) {
        if (!this.item.AircraftIdentificationUneditablePrefix && !event.target.value) {
            this.tailNumber = this.currentRegistrationPrefix;
        }
    }

    //hack for "Unable to check <input pattern='x'> because the pattern is not a valid regexp: invalid identity escape in regular expression"
    getPattern() {
        if (!this.item.AircraftIdentificationRegexExpression) return null;
        return this.item.AircraftIdentificationRegexExpression.replace("\\", "");
    }
}
