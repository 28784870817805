/// <reference path="../../node_modules/@types/jquery.ui.datetimepicker/index.d.ts"/>
import { environment } from './environments/environment';
import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SwxModule, GridModule, ExportApi, AuthModule, AuthInterceptorService } from 'swx.front-end-lib';
import { AuthGuard } from "./shared/AuthGuard.service";
import { TicketTrackingSystemService, TicketTrackingCommentDialogComponent } from './shared/TicketTrackingSystem.service';
import { ApiService } from './shared/Api.service';
import { HasPermissionPipe, HasPermissionService } from './shared/HasPermission.pipe';
import { FluidSortPipe } from './shared/FluidSort.pipe';
import { FormatTemperaturePipe } from './shared/FormatTemperature.pipe';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/Login.component';
import { HomeComponent } from './home/Home.component';
import { MenuComponent } from './menu/Menu.component';
import { UserDetailComponent, AirlineSelectionDialogComponent, ServiceProviderSelectionDialogComponent } from './user/UserDetail.component';
import { UserListComponent } from './user/UserList.component';
import { ServiceProviderDetailComponent, DeicingServiceTypeCellRendererComponent } from './serviceProvider/ServiceProviderDetail.component';
import { ServiceProviderListComponent } from './serviceProvider/ServiceProviderList.component';
import { DeicingFormConfigurationDetailComponent } from './deicingFormConfiguration/DeicingFormConfigurationDetail.component';
import { DeicingFormFieldOptionsDialogComponent } from './deicingFormConfiguration/DeicingFormFieldOptionsDialog.component';
import { DeicingFormConfigurationListComponent } from './deicingFormConfiguration/DeicingFormConfigurationList.component';
import { DeicingFluidListComponent } from './deicingFluid/DeicingFluidList.component';
import { DeicingFluidDetailComponent } from './deicingFluid/DeicingFluidDetail.component';
import { AirlineDetailComponent } from './airline/AirlineDetail.component';
import { AirlineListComponent } from './airline/AirlineList.component';
import { DeicingRecordDataUploadComponent } from './deicingRecord/DeicingRecordDataUpload.component';
import { DeicingRecordDetailComponent } from './deicingRecord/DeicingRecordDetail.component';
import { DeicingRecordGridComponent, DeicingRecordTruckCellRendererComponent, DeicingRecordTruckDialogComponent } from './deicingRecord/DeicingRecordGrid.component';
import { DeicingRecordSignatureDialogComponent } from './deicingRecordSignatureDialog/DeicingRecordSignatureDialog.component';
import { ForgotPasswordComponent } from './login/ForgotPassword.component';
import { ResetPasswordComponent } from './login/ResetPassword.component';
import { UserProfileComponent } from './login/UserProfile.component';
import { ChangeGridComponent, DiffCellRendererComponent } from './change/ChangeGrid.component';
import { RequireItemsDirective } from './requireItems/RequireItems.directive';
import { CoordinatorComponent } from './coordinator/Coordinator.component';
import { WeatherTypeDialogComponent } from './coordinator/WeatherTypeDialog.component';
import { IntensityDialogComponent } from './coordinator/IntensityDialog.component';
import { WeatherIconComponent } from './coordinator/WeatherIcon.component';
import { HotGraphComponent } from './hotGraph/HotGraph.component';
import { HotResponseComponent } from './hotResponse/HotResponse.component';
import { DeicingMobileConfigurationListComponent } from './deicingMobileConfiguration/DeicingMobileConfigurationList.component';
import { DeicingMobileConfigurationDetailComponent } from './deicingMobileConfiguration/DeicingMobileConfigurationDetail.component';
import { HotRequestHistoryComponent } from './hotRequestHistory/HotRequestHistory.component';
import { LoginHistoryComponent } from './loginHistory/LoginHistory.component';
import { TruckInfoComponent } from './truckInfo/TruckInfo.component';
import { InspectionCellRenderer } from './truckInfo/InspectionCellRenderer.component';
import { DeicingRecordImportLogComponent } from "./deicingRecordImportLog/deicingRecordImportLog.component";
import { DeicingRecordUploadLogComponent } from "./deicingRecordUploadLog/deicingRecordUploadLog.component";
import { RawDataCellRendererComponent, RawDataViewDialogComponent } from "./shared/cellRenderers/RawDataCellRenderer.component";
import { FileCellRendererComponent } from "./shared/cellRenderers/fileCellRenderer.component";
import { DeicingRecordsCellRendererComponent } from "./shared/cellRenderers/deicingRecordsCellRenderer.component";

@NgModule({ declarations: [
        HasPermissionPipe,
        FluidSortPipe,
        FormatTemperaturePipe,
        AppComponent,
        MenuComponent,
        LoginComponent,
        HomeComponent,
        UserDetailComponent,
        UserListComponent,
        ServiceProviderDetailComponent,
        DeicingServiceTypeCellRendererComponent,
        RequireItemsDirective,
        ServiceProviderListComponent,
        DeicingFormConfigurationDetailComponent,
        DeicingFormConfigurationListComponent,
        DeicingFormFieldOptionsDialogComponent,
        AirlineDetailComponent,
        AirlineSelectionDialogComponent,
        ServiceProviderSelectionDialogComponent,
        DeicingFluidDetailComponent,
        DeicingFluidListComponent,
        AirlineListComponent,
        DeicingRecordDataUploadComponent,
        DeicingRecordDetailComponent,
        DeicingRecordGridComponent,
        DeicingRecordImportLogComponent,
        DeicingRecordUploadLogComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        UserProfileComponent,
        ChangeGridComponent,
        DiffCellRendererComponent,
        TicketTrackingCommentDialogComponent,
        CoordinatorComponent,
        WeatherTypeDialogComponent,
        IntensityDialogComponent,
        WeatherIconComponent,
        HotGraphComponent,
        HotResponseComponent,
        DeicingMobileConfigurationDetailComponent,
        DeicingMobileConfigurationListComponent,
        HotRequestHistoryComponent,
        DeicingRecordTruckCellRendererComponent,
        DeicingRecordTruckDialogComponent,
        DeicingRecordSignatureDialogComponent,
        LoginHistoryComponent,
        TruckInfoComponent,
        InspectionCellRenderer,
        RawDataCellRendererComponent,
        RawDataViewDialogComponent,
        FileCellRendererComponent,
        DeicingRecordsCellRendererComponent,
    ],
    bootstrap: [
        AppComponent,
    ], imports: [BrowserModule,
        RouterModule.forRoot([
            { path: 'login', component: LoginComponent },
            { path: 'forgotPassword', component: ForgotPasswordComponent },
            { path: 'resetPassword/:id', component: ResetPasswordComponent },
            { path: 'users/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
            { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
            { path: 'serviceProviders', component: ServiceProviderListComponent, canActivate: [AuthGuard] },
            { path: 'serviceProviders/:id', component: ServiceProviderDetailComponent, canActivate: [AuthGuard] },
            {
                path: 'deicingFormConfigurations',
                component: DeicingFormConfigurationListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'deicingFormConfigurations/:id',
                component: DeicingFormConfigurationDetailComponent,
                canActivate: [AuthGuard],
            },
            { path: 'deicingFluids', component: DeicingFluidListComponent, canActivate: [AuthGuard] },
            { path: 'deicingFluids/:id', component: DeicingFluidDetailComponent, canActivate: [AuthGuard] },
            { path: 'airlines', component: AirlineListComponent, canActivate: [AuthGuard] },
            { path: 'airlines/:id', component: AirlineDetailComponent, canActivate: [AuthGuard] },
            {
                path: 'deicingRecordsData',
                component: DeicingRecordGridComponent,
                canActivate: [AuthGuard],
                data: { role: 'deicingRecords' },
            },
            { path: 'deicingRecords/:id', component: DeicingRecordDetailComponent, canActivate: [AuthGuard] },
            { path: 'deicingRecordsUpload', component: DeicingRecordDataUploadComponent, canActivate: [AuthGuard] },
            {
                path: 'airlineDeicingRecordsData',
                component: DeicingRecordGridComponent,
                canActivate: [AuthGuard],
                data: { role: 'airline' },
            },
            {
                path: 'flightOpsDeicingRecordsData',
                component: DeicingRecordGridComponent,
                canActivate: [AuthGuard],
                data: { role: 'flightOps' },
            },
            { path: 'changes', component: ChangeGridComponent, canActivate: [AuthGuard] },
            { path: 'userProfile', component: UserProfileComponent, canActivate: [AuthGuard] },
            { path: 'coordinator', component: CoordinatorComponent, canActivate: [AuthGuard] },
            {
                path: 'deicingMobileConfigurations',
                component: DeicingMobileConfigurationListComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'deicingMobileConfigurations/:id',
                component: DeicingMobileConfigurationDetailComponent,
                canActivate: [AuthGuard],
            },
            { path: '', component: HomeComponent },
            { path: 'loginHistory', component: LoginHistoryComponent, canActivate: [AuthGuard] },
            { path: 'truckInfo', component: TruckInfoComponent, canActivate: [AuthGuard] },
            { path: 'deicingRecordImportLog', component: DeicingRecordImportLogComponent, canActivate: [AuthGuard] },
            { path: 'deicingRecordUploadLog', component: DeicingRecordUploadLogComponent, canActivate: [AuthGuard] },
            //{ path: '**', component: PageNotFoundComponent }
        ]),
        FormsModule,
        GridModule,
        SwxModule.forRoot(environment),
        AuthModule.forRoot({
            tokenUrl: `${environment.apiUrl}/oauth/access_token`,
            userUrl: `${environment.apiUrl}/user/me`,
            storageKey: 'access_token',
        })], providers: [
        DecimalPipe,
        HasPermissionService,
        AuthGuard,
        TicketTrackingSystemService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        {
            provide: ExportApi,
            useClass: ApiService,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
