<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Service provider'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="serviceProviderEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewServiceProviderAirlines'|hasPermission)" (click)="switchTab('airlines')" [class.active]="tab == 'airlines'" [class.invalid]="!ngForm.form.controls.airlinesForm?.valid">{{'Airlines'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewServiceProviderContacts'|hasPermission)" (click)="switchTab('contacts')" [class.active]="tab == 'contacts'" [class.invalid]="!ngForm.form.controls.contactsForm?.valid">{{'Contacts'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewServiceProviderFluids'|hasPermission)" (click)="switchTab('deicingFluids')" [class.active]="tab == 'deicingFluids'" [class.invalid]="!ngForm.form.controls.deicingFluidsForm?.valid || deicingFluid != null">{{'Fluids'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewServiceProviderVehicles'|hasPermission)" (click)="switchTab('vehicles')" [class.active]="tab == 'vehicles'" [class.invalid]="!ngForm.form.controls.vehiclesForm?.valid">{{'Vehicles'|translate}}</a>
			<label>
				<input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine || deicingFluid != null" value="{{'Save'|translate}}" />
			</label>
			<label>
				<input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
			</label>
			<label>
				<input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
			</label>
		</div>

		<fieldset [disabled]="!('ConfigEditServiceProviders'|hasPermission)" class="contentMargins form" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Name'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Name" name="Name" maxlength="100" />
			</label>
			<label>
				<span class="caption">{{'Upload mapping code'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Code" name="Code" />
			</label>
			<label>
				<span class="caption">{{'Deicing Upload Formats' | translate}}</span>
                <multiselect name="item.DeicingRecordUploadFormatFlags" [(ngModel)]="item.DeicingRecordUploadFormatFlags" [all]="false" [format]="'string'">
                    <multiselect-option *ngFor="let format of deicingRecordFormats|keys" [value]="format" [name]="format"></multiselect-option>
                </multiselect>
            </label>
			<label *ngIf="('ConfigViewServiceProviderAirport'|hasPermission)">
				<span class="caption">{{'Airport'|translate}}</span>
				<select required [(ngModel)]="item.AirportId" name="item.AirportId" [disabled]="!('ConfigEditServiceProviderAirport'|hasPermission)">
					<option *ngFor="let airport of airports|orderBy:'ICAOCode'" [ngValue]="airport.Id">
						{{airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name}}
					</option>
				</select>
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="Active" />
			</label>
			<label>
				<span class="caption">{{'Comments'|translate}}</span>
				<textarea [(ngModel)]="item.Comments" name="Comments" style="width: 400px; height: 75px;"></textarea>
			</label>
			<div class="field">
				<span class="caption">{{'Logo'|translate}} <a *ngIf="item.Logo != null" (click)="confirm(translateService.translate('Are you sure?')) ? (item.Logo = null || ngForm.form.markAsDirty()) : false;" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a></span>
				<file-as-data-uri accept="image/*" [(ngModel)]="item.Logo" name="Logo" (ngModelChange)="logoChanged();"></file-as-data-uri>
				<br />
				<img *ngIf="item.Logo != null" [src]="item.Logo" />
			</div>
			<label>
				<span class="caption">{{'Deicing form configuration'|translate}}</span>
				<select required [(ngModel)]="item.DeicingFormConfigurationId" name="item.DeicingFormConfigurationId">
					<option *ngFor="let deicingFormConfiguration of deicingFormConfigurations|orderBy:'Name'" [ngValue]="deicingFormConfiguration.Id">
						{{deicingFormConfiguration.Name}}
					</option>
				</select>
			</label>
			<label>
				<span class="caption">{{'Deicing mobile configuration'|translate}}</span>
				<select [(ngModel)]="item.DeicingMobileConfigurationId" name="item.DeicingMobileConfigurationId">
					<option *ngFor="let deicingMobileConfiguration of deicingMobileConfigurations|orderBy:'Name'" [ngValue]="deicingMobileConfiguration.Id">
						{{deicingMobileConfiguration.Name}}
					</option>
				</select>
			</label>
			<label *ngIf="hasServiceProviderApi">
				<span class="caption">{{'API temperature unit'|translate}}</span>
				<select [(ngModel)]="item.ApiTemperatureUnit" name="item.ApiTemperatureUnit" required [selectedIndex]="-1">
					<option *ngFor="let option of temperatureUnits|keys" [ngValue]="option">
						{{temperatureUnits[option]}}
					</option>
				</select>
			</label>
			<label *ngIf="hasServiceProviderApi">
				<span class="caption">{{'API fluid volume unit'|translate}}</span>
				<select [(ngModel)]="item.ApiVolumeUnit" name="item.ApiVolumeUnit" required [selectedIndex]="-1">
					<option *ngFor="let option of volumeUnits|keys" [ngValue]="option">
						{{volumeUnits[option]}}
					</option>
				</select>
			</label>
			<label>
				<span class="caption">{{'Company type (GH, AP,AL, AM, DI)'|translate}}</span>
				<input type="text" [(ngModel)]="item.CompanyType" name="CompanyType" />
			</label>
			<label>
				<span class="caption">{{'Company is switching fluids?'|translate}}</span>
				<input type="text" [(ngModel)]="item.IsSwitchingFluids" name="IsSwitchingFluids" />
			</label>
			<label>
				<span class="caption">{{'Using international standards (SAE)?'|translate}}</span>
				<input type="text" [(ngModel)]="item.UsingSAEStandards" name="UsingSAEStandards" />
			</label>
			<label>
				<span class="caption">{{'Performing forced air deicing?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.PerformingForcedAirDeicing" name="PerformingForcedAirDeicing" />
			</label>
			<label>
				<span class="caption">{{'Performing forced air deicing with fluid injection?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.PerformingForcedAirDeicingWithFluidInjection" name="PerformingForcedAirDeicingWithFluidInjection" />
			</label>
			<label>
				<span class="caption">{{'Performing engine ice melting?'|translate}}</span>
				<input type="text" [(ngModel)]="item.PerformingEngineIceMelting" name="PerformingEngineIceMelting" />
			</label>
			<label>
				<span class="caption">{{'Engine ice melting performed by (if not performed by the company)'|translate}}</span>
				<input type="text" [(ngModel)]="item.EngineIceMeltingPerformedBy" name="EngineIceMeltingPerformedBy" />
			</label>
			<label>
				<span class="caption">{{'Deicing training for trainers performed by? (internal/external)'|translate}}</span>
				<input type="text" [(ngModel)]="item.DeicingTrainingForTrainersPerformedBy" name="DeicingTrainingForTrainersPerformedBy" />
			</label>
			<label>
				<span class="caption">{{'Name of external trainer'|translate}}</span>
				<input type="text" [(ngModel)]="item.NameOfExternalTrainer" name="NameOfExternalTrainer" />
			</label>
			<label>
				<span class="caption">{{'De-/Anti-icing performed at Gate?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.DeicingPerformedAtGate" name="DeicingPerformedAtGate" />
			</label>
			<label>
				<span class="caption">{{'De-/Anti-icing performed after pushback?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.DeicingPerformedAfterPushback" name="DeicingPerformedAfterPushback" />
			</label>
			<label>
				<span class="caption">{{'De-/Anti-icing performed at Remote/CDF?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.DeicingPerformedAtRemoteOrCDF" name="DeicingPerformedAtRemoteOrCDF" />
			</label>
			<label>
				<span class="caption">{{'De-/Anti-icing performed at end of taxiway?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.DeicingPerformedAtEndOfTaxiway" name="DeicingPerformedAtEndOfTaxiway" />
			</label>
			<label>
				<span class="caption">{{'De-/Anti-icing performed at other position, where?'|translate}}</span>
				<input type="text" [(ngModel)]="item.DeicingPerformedAtOtherPositionAndWhere" name="DeicingPerformedAtOtherPositionAndWhere" />
			</label>
			<label>
				<span class="caption">{{'Engines running during deicing?'|translate}}</span>
				<input type="text" [(ngModel)]="item.EnginesRunningDuringDeicing" name="EnginesRunningDuringDeicing" />
			</label>
			<label>
				<span class="caption">{{'How is fluid stored'|translate}}</span>
				<input type="text" [(ngModel)]="item.HowIsFluidStored" name="HowIsFluidStored" />
			</label>
			<label>
				<span class="caption">{{'Total Fluid capacity of all fluids (litres)'|translate}}</span>
				<input type="text" [(ngModel)]="item.TotalFluidCapacityLitres" name="TotalFluidCapacityLitres" />
			</label>
			<label>
				<span class="caption">{{'Total capacity of fluid type I (litres)'|translate}}</span>
				<input type="text" [(ngModel)]="item.TotalFluidType1CapacityLitres" name="TotalFluidType1CapacityLitres" />
			</label>
			<label>
				<span class="caption">{{'Total capacity of fluid type II/III/IV (litres)'|translate}}</span>
				<input type="text" [(ngModel)]="item.TotalFluidType4CapacityLitres" name="TotalFluidType4CapacityLitres" />
			</label>
			<label>
				<span class="caption">{{'Heating of type I and using as anti-icing'|translate}}</span>
				<input type="text" [(ngModel)]="item.HeatingOfType1AndUsingAsAntiIcing" name="HeatingOfType1AndUsingAsAntiIcing" />
			</label>
			<label *ngIf="('DeicingExportAccountingReport'|hasPermission)">
				<span class="caption">{{'Show Accounting Export button ?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.ShowAccountingExport" name="ShowAccountingExport" />
			</label>
		</fieldset>

		<div class="flexTab" *ngIf="airlinesColumnDefs != null" [style.display]="tab == 'airlines' ? '' :'none'">
			<div class="options contentMargins">
				<div *ngIf="airlinesForm?.errors && airlinesForm?.errors.requireItems === false" class="error" style="color: #ee3333; font-weight: bold; padding: 10px 0;">{{'Please add at least one airline'|translate}}</div>
				<label class="inline search">
					<span class="mdi mdi-magnify"></span>
					<input [(ngModel)]="airlineSearchQuery" [ngModelOptions]="{standalone: true}" name="airlineSearchQuery" (ngModelChange)="airlinesGrid.gridApi.setRowData(filter(transformDeicingServiceProviderAirlines(item.DeicingServiceProviderAirlines), airlineSearchQuery));" />
				</label>
				<label class="inline">
					<input type="button" class="green" *ngIf="('ConfigEditServiceProviderAirlines'|hasPermission)" (click)="showAirlinesPopup();" value="{{'Add airlines...'|translate}}" />
				</label>
                <label class="inline">
                    <span class="caption">&nbsp;</span>
                    <button type="button" [disabled]="item.Id === 0" (click)="airlinesGrid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
                </label>
			</div>

            <config-grid #airlinesGrid
                         class="tabContent"
                         ngModelGroup="airlinesForm"
                         #airlinesForm="ngModelGroup"
                         [requireItems]="item.DeicingServiceProviderAirlines"
                         gridId="preferences.ServiceProviderAirline.ColumnExport"
                         [query]="exportDeicingServiceProviderDeicingAirlinesQuery"
                         [exportResource]="exportDeicingServiceProviderDeicingAirlinesResource"
                         [columnDefs]="airlinesColumnDefs"
                         [rowData]="transformDeicingServiceProviderAirlines(item.DeicingServiceProviderAirlines)">
            </config-grid>
		</div>

		<div class="flexTab" [style.display]="tab == 'deicingFluids' ? '' :'none'">
			<div class="options contentMargins">
				<label class="inline search">
					<span class="mdi mdi-magnify"></span>
					<input [(ngModel)]="deicingFluidSearchQuery" [ngModelOptions]="{standalone: true}" name="deicingFluidSearchQuery" />
				</label>
				<label class="inline">
					<select [(ngModel)]="deicingFluid" [ngModelOptions]="{standalone: true}" name="deicingFluid">
						<option *ngFor="let deicingFluid of deicingFluids|orderBy:['Type', 'Name']" [ngValue]="deicingFluid">{{'Type'|translate}} {{deicingFluid.Type}} - {{deicingFluid.Name}}</option>
					</select>
					<input type="button" class="green" *ngIf="('ConfigEditServiceProviderFluids'|hasPermission)" (click)="addServiceProviderDeicingFluid(deicingFluid); deicingFluid = null;" value="{{'Add fluid'|translate}}" [disabled]="deicingFluid == null"/>
				</label>
			</div>
			<fieldset [disabled]="!('ConfigEditServiceProviderFluids'|hasPermission)" class="tabContent form" ngModelGroup="deicingFluidsForm" #deicingFluidsForm="ngModelGroup">
				<table class="table">
					<tr>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="(deicingFluids|findById:serviceProviderDeicingFluid.DeicingFluidId)?.Type">{{'Type'|translate}}</th>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="(deicingFluids|findById:serviceProviderDeicingFluid.DeicingFluidId)?.Name">{{'Fluid'|translate}}</th>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderContact.ManufacturerPremix">{{'Manufacturer premix'|translate}}</th>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderContact.Dilution">{{'Dilution'|translate}}</th>
						<th></th>
					</tr>
					<tr *ngFor="let serviceProviderDeicingFluid of serviceProviderSearchFilter(item.DeicingServiceProviderDeicingFluids, deicingFluidSearchQuery, 'serviceProviderDeicingFluid')|applySort:orderByFieldOne:orderByReverseOne:this:'serviceProviderDeicingFluid'; let i=index">
						<td>{{'Type'|translate}} {{(deicingFluids|findById:serviceProviderDeicingFluid.DeicingFluidId)?.Type}}</td>
						<td>{{(deicingFluids|findById:serviceProviderDeicingFluid.DeicingFluidId)?.Name}}</td>
						<td>
							<input type="text" [(ngModel)]="serviceProviderDeicingFluid.ManufacturerPremix" name="serviceProviderDeicingFluid.ManufacturerPremix[{{i}}]" />
						</td>
						<td>
							<input type="text" [(ngModel)]="serviceProviderDeicingFluid.Dilution" name="serviceProviderDeicingFluid.Dilution[{{i}}]" />
						</td>
						<td>
							<a *ngIf="('ConfigEditServiceProviderFluids'|hasPermission)" (click)="removeServiceProviderDeicingFluid(serviceProviderDeicingFluid);" class="mdi mdi-delete"></a>
						</td>
					</tr>
				</table>
			</fieldset>
		</div>

		<div class="flexTab" [style.display]="tab == 'contacts' ? '' :'none'">
			<div class="options contentMargins">
				<label class="inline search">
					<span class="mdi mdi-magnify"></span>
					<input [(ngModel)]="contactSearchQuery" [ngModelOptions]="{standalone: true}" name="contactSearchQuery" />
				</label>
				<label class="inline">
					<input type="button" *ngIf="('ConfigEditServiceProviderContacts'|hasPermission)" (click)="addServiceProviderContact();" value="{{'Add contact'|translate}}" />
				</label>
			</div>
			<fieldset [disabled]="!('ConfigEditServiceProviderContacts'|hasPermission)" class="tabContent form" [style.display]="tab == 'contacts' ? '' :'none'" ngModelGroup="contactsForm" #contactsForm="ngModelGroup">
				<table class="table">
					<tr>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderContact.Title">{{'Title'|translate}}</th>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderContact.Name">{{'Name'|translate}}</th>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderContact.Email">{{'Email'|translate}}</th>
						<th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderContact.PhoneNumber">{{'Phone'|translate}}</th>
						<th></th>
					</tr>
					<tr *ngFor="let serviceProviderContact of serviceProviderSearchFilter(item.DeicingServiceProviderContacts, contactSearchQuery, 'serviceProviderContact')|applySort:orderByFieldOne:orderByReverseOne:this:'serviceProviderContact'; let i=index">
						<td>
							<input type="text" [(ngModel)]="serviceProviderContact.Title" name="serviceProviderContact.Title[{{i}}]" />
						</td>
						<td>
							<input type="text" [(ngModel)]="serviceProviderContact.Name" name="serviceProviderContact.Name[{{i}}]" />
						</td>
						<td>
							<input type="text" [(ngModel)]="serviceProviderContact.Email" name="serviceProviderContact.Email[{{i}}]" />
						</td>
						<td>
							<input type="text" [(ngModel)]="serviceProviderContact.PhoneNumber" name="serviceProviderContact.PhoneNumber[{{i}}]" />
						</td>
						<td>
							<a *ngIf="('ConfigEditServiceProviderContacts'|hasPermission)" (click)="removeServiceProviderContact(serviceProviderContact);" class="mdi mdi-delete"></a>
						</td>
					</tr>
				</table>
			</fieldset>
		</div>

    <div class="flexTab" [style.display]="tab == 'vehicles' ? '' :'none'">
        <div class="options contentMargins">
            <label class="inline search">
                <span class="mdi mdi-magnify"></span>
                <input [(ngModel)]="vehicleSearchQuery" [ngModelOptions]="{standalone: true}" name="vehicleSearchQuery"/>
            </label>
            <label class="inline">
                <input type="button" *ngIf="('ConfigEditServiceProviderVehicles'|hasPermission)" (click)="addServiceProviderVehicle();" value="{{'Add vehicle'|translate}}"/>
            </label>
        </div>
        <fieldset [disabled]="!('ConfigEditServiceProviderVehicles'|hasPermission)" class="tabContent form" ngModelGroup="vehiclesForm" #vehiclesForm="ngModelGroup">
            <table class="table">
                <tr>
                    <th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderVehicle.ManufacturerAndModelCategory">{{'Custom name'|translate}}</th>
                    <th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderVehicle.ManufacturerAndModel">{{'Manufacturer and model'|translate}}</th>
                    <th order-by-field="orderByFieldOne" order-by-reverse="orderByReverseOne" sortable-column="serviceProviderVehicle.MixingOfFluid">{{'Mixing of fluid'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let serviceProviderVehicle of serviceProviderSearchFilter(item.DeicingServiceProviderVehicles, vehicleSearchQuery, 'serviceProviderVehicle')|applySort:orderByFieldOne:orderByReverseOne:this:'serviceProviderVehicle'; let i=index">
                    <td>
                        <input type="text" (keyup)="checkServiceProviderVehicleUnicity(serviceProviderVehicle, $index)" [(ngModel)]="serviceProviderVehicle.ManufacturerAndModelCategory" name="serviceProviderVehicle.ManufacturerAndModelCategory[{{i}}]" required/>
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="serviceProviderVehicle.ManufacturerAndModel" (ngModelChange)="prefillVehicleCustomName(serviceProviderVehicle);" name="serviceProviderVehicle.ManufacturerAndModel[{{i}}]" required/>
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="serviceProviderVehicle.MixingOfFluid" name="serviceProviderVehicle.MixingOfFluid[{{i}}]"/>
                    </td>
                    <td>
                        <a *ngIf="('ConfigEditServiceProviderVehicles'|hasPermission)" (click)="removeServiceProviderVehicle(serviceProviderVehicle);" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
        </fieldset>
    </div>

	</div>
</form>
