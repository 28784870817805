import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { ApiService } from '../shared/Api.service';
import { InspectionCellRenderer } from './InspectionCellRenderer.component';
import { DialogService, FileDownloadService, GridComponent, ValueFormatters } from 'swx.front-end-lib';
import { DeicingRecordSignatureDialogComponent } from "../deicingRecordSignatureDialog/DeicingRecordSignatureDialog.component";

@Component({
    selector: 'truck-info',
    templateUrl: 'TruckInfo.component.html'
})
export class TruckInfoComponent implements OnInit {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    query: any = {};
    airports = [];
    selectedIds: number[] = [];

    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
    };

    components = {
        inspectionCellRenderer: InspectionCellRenderer
    };

    columnDefs = [
        {
            headerName: 'Date (UTC)',
            field: 'CertificationTime',
            colId: 'CertificationTime',
            valueFormatter: this.formatters.utcDateTimeFormatter(),
            filterType: 'date',
            pinned: 'left',
            width: 140,
            checkboxSelection: true
        },
        {
            headerName: 'Airport',
            field: 'AirportCode',
            colId: 'AirportCode',
            width: 100
        },
        {
            headerName: 'Truck #',
            field: 'Truck',
            colId: 'Truck',
            width: 100
        },
        {
            headerName: 'Inspection',
            field: 'Inspection',
            valueGetter: ({ data }) => {
                if (data && data.Inspection) {
                    return JSON.parse(data.Inspection).map(item => `${item.Name} ${item.Status}`).join(', ');
                }

                return '';
            },
            colId: 'Inspection'
        },
        {
            headerName: 'Remarks',
            field: 'Remarks',
            colId: 'Remarks'
        },
        {
            headerName: 'Refractometer',
            field: 'Refractometer',
            colId: 'Refractometer',
            valueGetter: ({ data }) => {
                if (data) {
                    return data.Refractometer || 'Not provided'
                }
            }
        },
        {
            headerName: 'Accuracy Test Completed',
            field: 'AccuracyTestCompleted',
            valueFormatter: this.formatters.yesNoFormatter(),
            colId: 'AccuracyTestCompleted',
            width: 100,
            cellStyle: { textAlign: 'center' },
            filterType: 'boolean'
        },
        {
            headerName: 'Type 1 Brix',
            field: 'Type1Brix',
            colId: 'Type1Brix',
            filterType: 'float',
            width: 100
        },
        {
            headerName: 'Type 1 Mix Percent',
            field: 'Type1MixPercent',
            colId: 'Type1MixPercent',
            filterType: 'integer',
            width: 100
        },
        {
            headerName: 'Deicing LOUT',
            field: 'DeicingLout',
            colId: 'DeicingLout',
            filterType: 'integer',
            width: 100,
            valueFormatter: this.formatters.temperatureFormatter()
        },
        {
            headerName: 'Type 4 Brix',
            field: 'Type4Brix',
            colId: 'Type4Brix',
            filterType: 'float',
            width: 100
        },
        {
            headerName: 'Type 4 Mix Percent',
            field: 'Type4MixPercent',
            colId: 'Type4MixPercent',
            filterType: 'integer',
            width: 100
        },
        {
            headerName: 'Anti-Icing LOUT',
            field: 'AntiIcingLout',
            colId: 'AntiIcingLout',
            filterType: 'integer',
            width: 100,
            valueFormatter: this.formatters.temperatureFormatter()
        },
        {
            headerName: 'Certified By',
            field: 'CertifiedBy',
            colId: 'CertifiedBy'
        },
        {
            headerName: 'Certification Time (Airport Local)',
            field: 'CertificationTimeLocal',
            colId: 'CertificationTimeLocal',
            filterType: 'date'
        },
        {
            headerName: 'Signature',
            field: 'HasSignature',
            colId: 'HasSignature',
            cellRenderer: c => {
                var container = document.createElement('div');
                if (c.value) {
                    container.innerHTML = '<a href onclick="event.preventDefault();" class="mdi mdi-eye"></a>';
                    container.querySelector('a').addEventListener('click',
                        () => {
                            this.api.TruckInfoSignature.export({ id: c.data.Id }).then(response => {
                                var fr = new FileReader();
                                fr.onload = () => {
                                    this.dialogService.show(this.viewContainerRef, DeicingRecordSignatureDialogComponent,
                                        {
                                            src: fr.result
                                        },
                                        {
                                            title: 'Certification signature',
                                            width: 640,
                                            height: 320,
                                            modal: true,
                                        });
                                };
                                fr.readAsDataURL(response.body);
                            });
                        });
                }
                return container;
            },
            cellStyle: { textAlign: 'center' },
            width: 85,
            searchable: false
        },
        {
            colId: 'InspectionForm',
            headerName: 'Inspection Form',
            field: 'InspectionForm',
            cellRenderer: c => {
                var container = document.createElement('span');
                if (c.data) {
                    
                    container.innerHTML = '<a onclick="event.preventDefault();">download</a>';
                    container.querySelector('a').addEventListener('click',
                        () => {
                            this.api.DeicingVehicleSafetyInspectionForm.export({ ids: [c.data.Id] }).then(response => {
                                this.fileDownloadService.download(response.body, header => response.headers.get(header));
                            });
                        });
                }
                return container;
            },
            cellStyle: { textAlign: 'center' },
            width: 100,
            sortable: false,
            searchable: false
        }
    ];

    refresh() {
        window.localStorage['truckInfoQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    resetQuery() {
        this.query = {
            FromDate: null,
            ToDate: null,
            Filters: [],
            AirportId: []
        };
    }

    constructor(
        public api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private dialogService : DialogService,
        private fileDownloadService: FileDownloadService,
        private formatters: ValueFormatters,
    )
    {
        if (window.localStorage['truckInfoQuery']) {
            this.query = JSON.parse(window.localStorage['truckInfoQuery']);
        } else {
            this.resetQuery();
        }
    }

    ngOnInit(): void {
        // Initialize airports
        this.api.Airport.query().$promise.then((airports) => {
            this.airports = airports;
        });
    }

    downloadSelectedForms() {
        this.api.DeicingVehicleSafetyInspectionForm.export({ ids: this.selectedIds }).then((response) => {
            this.fileDownloadService.download(response.body, header => response.headers.get(header));
        });
    }
}
