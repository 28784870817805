<form class="form" #ngForm="ngForm" (submit)="upload()">
	<page-title>{{('Upload deicing data'|translate)}}</page-title>
	<label>
		<span class="caption">{{'Time zone'|translate}}</span>
		<select [(ngModel)]="query.TimeZone" name="TimeZone" required [selectedIndex]="-1">
			<option *ngFor="let option of deicingRecordTimeZones|keys" [ngValue]="option">
				{{deicingRecordTimeZones[option]}}
			</option>
		</select>
	</label>
	<label *ngIf="airlines.length > 1">
		<span class="caption">{{'Airline'|translate}}</span>
		<select [(ngModel)]="query.AirlineId" name="AirlineId">
			<option *ngFor="let option of ([{ Id: -1, Name: 'Use flight number column'}, { Id: null, Name: 'Use airline column'}]).concat(airlines|orderBy:'ICAOCode')" [ngValue]="option.Id">
				{{option.Name}}
			</option>
		</select>
	</label>
	<label>
		<span class="caption">{{'Airport'|translate}}</span>
		<select [(ngModel)]="query.AirportId" name="AirportId">
			<option *ngFor="let option of ([{ Id: null, Name: 'Use airport/station column'}]).concat(airports|orderBy:'ICAOCode')" [ngValue]="option.Id">
				{{(option.ICAOCode ? (option.ICAOCode + ' - ') : '') + option.Name}}
			</option>
		</select>
	</label>
	<label *ngIf="serviceProviders.length > 1">
		<span class="caption">{{'Service provider'|translate}}</span>
		<select [(ngModel)]="query.ServiceProviderId" name="ServiceProviderId">
			<option *ngFor="let option of ([{ Id: -1, Name: 'Use provider available at airport for airline'}, { Id: null, Name: 'Use provider/vendor column'}]).concat(serviceProviders|orderBy:'Name')" [ngValue]="option.Id">
				{{option.Name}}
			</option>
		</select>
	</label>
    <label>
        <span class="caption">{{'Data format'|translate}}</span>
        <select [(ngModel)]="query.Format" name="Format" required [selectedIndex]="-1">
            <option *ngFor="let option of uploadFormats" [ngValue]="option">
                {{option}}
            </option>
        </select>
    </label>
    <label>
		<span class="caption">{{'Flight'|translate}}</span>
		<select [(ngModel)]="query.MatchIdentifierField" name="MatchIdentifierField" required [selectedIndex]="-1">
			<option *ngFor="let option of matchIdentifierFields|keys" [ngValue]="option">
				{{matchIdentifierFields[option]}}
			</option>
		</select>
	</label>
	<label>
		<span class="caption">{{'Consolidate multiple rows for same flight'|translate}}</span>
		<input type="checkbox" [(ngModel)]="query.ConsolidateMultipleRowsForSameFlight" name="ConsolidateMultipleRowsForSameFlight" />
	</label>
	<label>
		<span class="caption">{{'File'|translate}}</span>
		<file-as-data-uri required [(ngModel)]="query.Data" name="Data" (fileLoaded)="query.Filename = $event.name"></file-as-data-uri>
	</label>
	<br />
	<input type="button" (click)="simulate()" [disabled]="!ngForm.form.valid || ngForm.form.pristine || !query.Data" value="{{'Simulate'|translate}}" />
	<br />
	<br />
	<input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine || !query.Data" value="{{'Upload'|translate}}" />

	<br />
	<br />
	<div ng-if="'DeicingDeleteRecords'|hasPermission">
		<h3>{{'Delete existing data for selected provider/airline/airport and dates:'|translate}}</h3>
		<label>
			<span class="caption">{{'From'|translate}}</span>
			<input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate" />
		</label>
		<label>
			<span class="caption">{{'To'|translate}}</span>
			<input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate" />
		</label>
		<br />
		<input type="button" (click)="delete()" [disabled]="!query.ServiceProviderId || !query.AirlineId || !query.FromDate || !query.ToDate" value="{{'Delete'|translate}}" />
	</div>
</form>
<div class="result" *ngIf="!!result">
    <h2>{{'Result'|translate}}</h2>
    <div *ngFor="let name of result|keys">
        <div *ngIf="name != 'Warnings' && name != 'Error'">
            <label>{{name}}</label>: {{result[name]}}
        </div>
        <div style="color: #ff8c00; font-weight: bold;" *ngIf="name == 'Warnings' && result.Warnings?.length">
			<div *ngFor="let warning of result[name]"><label>{{'Warning'|translate}}</label>: {{warning}}</div>
        </div>
        <div style="color: red; font-weight: bold;" *ngIf="name == 'Error'">
            <label>{{'Error'|translate}}</label>: {{result.Error}}
        </div>
    </div>
</div>