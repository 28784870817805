import { Component, OnChanges, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { RootScope } from '../shared/RootScope.service';
import { Fluid } from '../shared/Api.service';
import jQuery from 'jquery';
import { GoogleChartsService, IResourceItemMap } from 'swx.front-end-lib';

export interface GraphHots {
    [fluidId: number]: number|null;
}

@Component({
    selector: 'hot-graph',
    templateUrl: 'HotGraph.component.html'
})
export class HotGraphComponent implements OnChanges, AfterViewInit {
    @Input('data') graphHots: IResourceItemMap<GraphHots>;
    @Input('fluids') fluids: Fluid[];
    @Input('forType1') forType1?: boolean;
    @Input('count') count: number;
    @Input('useFluidColors') useFluidColors: boolean;
    @Input('options') options: google.visualization.LineChartOptions = {};
    @ViewChild('chartEl', { static: false }) chartEl: ElementRef<HTMLElement>;
    hotHistoryGraph: google.visualization.LineChart;
    chartLoad: any;

    constructor(
        private googleChartsLoaderService: GoogleChartsService,
        public $root: RootScope,
    ) {
        this.chartLoad = this.googleChartsLoaderService.loadGoogleCharts();

        var resizeTimer;
        jQuery(window).bind('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                this.drawChart();
            }, 250);
        });
    }

    ngAfterViewInit(): void {
        this.googleChartsLoaderService.loadGoogleCharts().then(() => {
            this.hotHistoryGraph = new google.visualization.LineChart(this.chartEl.nativeElement);
        });
    }

    ngOnChanges(): void {
        this.googleChartsLoaderService.loadGoogleCharts().then(() => this.drawChart());
    }

    private drawChart(): void {
        if (this.graphHots == null) return;

        Promise.all([this.graphHots.$promise, this.chartLoad]).then(() => {
            var dates = Array.from(this.graphHots.keys()).sort();

            var data = new google.visualization.DataTable();
            data.addColumn('datetime', 'Time');

            var series = {};
            this.fluids.forEach((fluid, index) => {
                if (typeof this.forType1 !== 'undefined' && this.forType1 !== null) {
                    if ((this.forType1 && fluid.FluidTypeNumber !== 1) || (!this.forType1 && fluid.FluidTypeNumber === 1)) return;
                }

                data.addColumn('number', 'TYPE ' + fluid.FluidTypeNumber + ': ' + fluid.ClientFluidName + (fluid.FluidTypeNumber === 1 ? '' : (' ' + fluid.Dilution)));

                var seriesConfig: any = {};

                if (this.useFluidColors) {
                    var isComposite = fluid.WingMaterialType === 'Composite';
                    var isGeneric = fluid.Name.toUpperCase().indexOf("GENERIC") !== -1;

                    if (fluid.FluidTypeNumber === 1) {
                        seriesConfig.color = isComposite ? '#ccaa77' : '#ffcc66';
                        seriesConfig.lineWidth = 4;
                    }

                    if (fluid.FluidTypeNumber === 2) {
                        seriesConfig.color = isGeneric ? '#cccccc' : '#dddddd';
                        seriesConfig.lineWidth = 4;
                    }

                    if (fluid.FluidTypeNumber === 3) {
                        seriesConfig.color = isGeneric ? '#cccc33' : '#ffff33';
                        seriesConfig.lineWidth = 4;
                    }

                    if (fluid.FluidTypeNumber === 4) {
                        seriesConfig.color = isGeneric ? '#99cc66' : '#99ff66';
                        seriesConfig.lineWidth = 4;
                    }

                    if (fluid.Dilution === '75/25') seriesConfig.lineDashStyle = [12, 4];
                    if (fluid.Dilution === '50/50') seriesConfig.lineDashStyle = [8, 8];
                }

                series[index] = seriesConfig;
            });

            var maxHot = 0;

            this.graphHots.forEach((hots, date) => {
                var row: any[] = [new Date(this.parseDate(date))];

                this.fluids.forEach((fluid, index) => {
                    if (typeof hots[fluid.Id] !== 'undefined') {
                        var fluidHot = hots[fluid.Id];

                        if (typeof this.forType1 !== 'undefined' && this.forType1 !== null) {
                            if ((this.forType1 && fluid.FluidTypeNumber !== 1) || (!this.forType1 && fluid.FluidTypeNumber === 1)) return;
                        }

                        if (fluidHot > maxHot) maxHot = fluidHot;

                        row.push(fluidHot);
                    } else {
                        row.push(null);
                    }
                });

                data.addRow(row);
            });

            this.options = Object.assign({
                    height: 500,
                    width: '100%',
                    chartArea: {
                        bottom: 120,
                        left: 120
                    },
                    backgroundColor: {
                        stroke: '#31b0d5',
                        strokeWidth: 1
                    },
                    isStacked: true,
                    allowHtml: true,
                    fill: 20,
                    hAxis: {
                        title: 'Time',
                        format: 'HH:mm',
                        gridlines: {
                            count: this.count
                        },
                        viewWindowMode: 'maximized',
                        viewWindow: {
                            min: new Date(this.parseDate(dates[0])),
                            max: new Date(this.parseDate(dates[dates.length - 1])),
                        }
                    },
                    legend: {
                        position: 'top'
                    },
                    enableInteractivity: false,
                    interpolateNulls: true,
                    series: series,
                    vAxis: {
                        title: 'HOT (minutes)',
                        viewWindow: {
                            min: 0,
                            max: maxHot > 48 ? 240 : (maxHot > 24 ? 48 : 24)
                        }
                    }
                },
                this.options);

            this.hotHistoryGraph.draw(data, this.options);
        });
    }

    private parseDate(dateString) {
        var parts = dateString.split(/[^0-9]/);
        return new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
    }
}
