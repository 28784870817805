import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, BusyIndicatorService, PageTitleService } from 'swx.front-end-lib';
import { ApiService } from './shared/Api.service';
import { HasPermissionService } from './shared/HasPermission.pipe';
import { RootScope } from './shared/RootScope.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    currentUser;
    serviceProvider;
    airline;
    logo;
    @ViewChild('title', { static: true }) title;
    @ViewChild('menuContainer', { static: true }) menuContainer;
    menuVisible = false;

    constructor(
        private router: Router,
        private api: ApiService,
        private authentication: AuthenticationService,
        public busyIndicatorService: BusyIndicatorService,
        private pageTitleService: PageTitleService,
        public hasPermissionService: HasPermissionService,
        private $root: RootScope
    ) {
    }

    ngOnInit(): void {
        this.pageTitleService.registerTitleElement(this.title.nativeElement);
        this.pageTitleService.setSiteName('SureWx Deicing Management');
        
        this.authentication.getCurrentUser()
            .then(user => this.currentUser = user,
                () => {
                    if (location.pathname.indexOf('/login') !== 0
                            && location.pathname.indexOf('/forgotPassword') !== 0
                            && location.pathname.indexOf('/resetPassword') !== 0) {
                        this.router.navigateByUrl('/login');
                    }
                });

        const updateServiceProvider = () => {
            Promise.all([this.$root.getServiceProviders(), this.$root.getAirlines()]).then(([serviceProviders, airlines]) => {
                this.currentUser = this.$root.currentUser;

                this.logo = null;

                this.serviceProvider = null;
                if (this.currentUser.PrimaryDeicingServiceProviderId) {
                    this.serviceProvider = serviceProviders.find(a => a.Id === this.currentUser.PrimaryDeicingServiceProviderId);
                } else if (serviceProviders.length === 1) {
                    this.serviceProvider = serviceProviders[0];
                }

                if (this.serviceProvider != null && this.$root.currentUser.DeicingUserLogoSource === 'ServiceProvider') {
                    this.logo = this.serviceProvider.Logo;
                }

                this.airline = null;
                if (this.currentUser.PrimaryDeicingAirlineId) {
                    this.airline = airlines.find(a => a.Id === this.currentUser.PrimaryDeicingAirlineId);
                } else if (airlines.length === 1) {
                    this.airline = airlines[0];
                }

                if (this.airline != null && this.$root.currentUser.DeicingUserLogoSource === 'Airline') {
                    this.logo = this.airline.CoordinatorLogo;
                }

                var userOnlyHasCoordinator = this.currentUser.Permissions
                    .split(',')
                    .map(p => p.trim())
                    .every(p => p === 'Coordinator' || p === 'CoordinatorDeicingRequests' || p === 'CoordinatorHotGraph');
        
                if (location.pathname === '/' || location.pathname === '/login') {
                    if (this.hasPermissionService.hasPermission('DataDeicing')) {
                        this.router.navigateByUrl('/deicingRecordsData');
                    } else if (this.hasPermissionService.hasPermission('DeicingAddRecord')) {
                        this.router.navigateByUrl('/deicingRecords/new');
                    } else if (userOnlyHasCoordinator) {
                        this.router.navigateByUrl('/coordinator');
                    } else if (location.pathname !== '/') {
                        this.router.navigateByUrl('/');
                    }
                }

                if (window.localStorage['menuVisible']) {
                    this.menuVisible = JSON.parse(window.localStorage['menuVisible']);
                    if (this.menuVisible) {
                        this.showMenu();
                    }
                } else {
                    if (!userOnlyHasCoordinator) {
                        this.showMenu();
                    }
                }
            });
        };

        this.$root.serviceProviderChange.subscribe(() => updateServiceProvider());
        Promise.all([this.$root.getServiceProviders(), this.$root.getAirlines()]).then(() => updateServiceProvider());
    }

    toggleMenu() {
        if (this.menuVisible) {
            this.hideMenu();
        } else {
            this.showMenu();
        }
    }

    showMenu() {
        document.documentElement.style.setProperty('--menu-width', '240px');
        this.menuVisible = true;
        window.localStorage['menuVisible'] = JSON.stringify(this.menuVisible);
    }

    hideMenu() {
        document.documentElement.style.setProperty('--menu-width', '0');
        this.menuVisible = false;
        window.localStorage['menuVisible'] = JSON.stringify(this.menuVisible);
    }

    logout() {
        this.authentication.clearCredentials();
        this.router.navigateByUrl('/login');
    }
}
