<div class="container">
	<div class="header">
		<button class="toggleMenu" (click)="toggleMenu()"><span class="mdi mdi-menu"></span></button>
		<div class="userLogo" *ngIf="logo != null" [style.background-image]="'url(' + logo + ')'"></div>
        <h1 #title></h1>
		<div class="logo" [title]="'SureWx'|translate"></div>
		<div class="currentUser" *ngIf="currentUser">
			<div>{{currentUser.Name}}</div>
			<a (click)="logout()">{{'Log out'|translate}}</a>
			<ng-container *ngIf="'UserSettings'|hasPermission">
				| <a routerLink="/userProfile"><span>{{'Settings'|translate}}</span></a>
			</ng-container>
		</div>
		<div *ngIf="busyIndicatorService.busy" class="busyIndicator"></div>
	</div>
    <div class="content">
        <div class="menuContainer" #menuContainer>
            <div menu *ngIf="currentUser"></div>
        </div>
        <div class="main">
            <div class="contentWrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
