import { Injectable } from '@angular/core';
import { IResourceItem, ApiFactoryService, ExportApi } from 'swx.front-end-lib';

export abstract class Fluid implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    Id: number;
    FluidSeasonId: number;
    ClientFluidName: string;
    Order: number;
    Name: string;
    Dilution: string;
    Type: string;
    WingMaterialType: string;
    FluidTypeNumber: number;
    ClientId: number;
    Airports: Array<number>;
}

export abstract class CoordinatorAirline implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    Id: number;
    ClientId: number;
    IATACode: string;
    ICAOCode: string;
    Name: string;
    CoordinatorLogo: string;
}

export abstract class CoordinatorAirport implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    Id: number;
    IATACode: string;
    ICAOCode: string;
    Name: string;
    TimeZone: string;
}

export abstract class CoordinatorClientAirport implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    AirportId: number;
    ClientId: number;
    Preferred: boolean;
    IsClear: boolean;
    IsClearSky: boolean;
    IsNightTime: boolean;
    IsFreezing: boolean;
    Type: string;
    WeatherTypeNameResult: string;
    MetarReadingString: string;
    RequestDateTime;
    HotResponse: HotResponse;
    StationSimulationId?: number;
    StationGen2Id?: number;
    StationGen3Id?: number;
    StationGenVId?: number;
    BarrelIcingConditionsPresent: boolean;
    ActiveFrostConditionsPresent: boolean;
    TimeZone: string;

    Type1Hot: HotResponseHot;
    Type2Hot: HotResponseHot;
    Type3Hot: HotResponseHot;
    Type4Hot: HotResponseHot;
}

export class HotResponseHot {
    FluidId: number;
    FluidSeasonId: number|null;
    FluidType: string;
    FluidDilution: string;
    FluidName: string;
    SortableDilution?: number;
    Message: string;
    FluidTypeNumber: number;
    MinHot?: number;
    MaxHot?: number;
    NonHotMessage: string;
    IsGeneric: boolean;
    IsComposite: boolean;
}

export class HotResponse {
    DateTime: string;
    WeatherType: string;
    WeatherTypeCode: string;
    WeatherReport: string;
    WeatherTypeName: string;
    Message: string;
    Type: string;
    MetarUsageReason: string;
    Temperature?: number;
    FluidMessage: string;
    ForecastMessage: string;

    HotResponseHots: Array<HotResponseHot>;
}

export class MobileClient {
    ShowOnlyMinHot: boolean;
    OatBelowFluidLoutError: string;
    MobileOatBelowFluidLoutError: string;
    FluidMetarSpecNotFoundError: string;
    MobileFluidMetarSpecNotFoundError: string;
    MetarEmptyWeatherCodeError: string;
    MobileMetarEmptyWeatherCodeError: string;
    MobileAirportError: string;
    MobileDefaultHotText: string;
    MobileSystemError: string;
    MobileShowMetarWeatherDescription: boolean;
    ClientFluids: Array<MobileClientFluid>;
}

export class MobileWeatherInfo {
    MetarWeatherTypeId?: number;
    Name: string;
    Order: number;
    SnowVisibilityMenu: SnowVisibilitiesInfo;
    Items?: Array<MobileWeatherInfo>;
}

export class SnowVisibilitiesInfo {
    SelectedIntensityTitle: string;
    ShowSnowVisibilityDocument: boolean;
    ShowSnowVisibilityText: boolean;
    PortalIntensities?: SnowVisibilitiesMenuIntensity[];
    TemperatureOptions: SnowVisibilitiesMenuTemperatureOption[];
}

export class SnowVisibilitiesMenuIntensity {
    Name: string;
    MetarWeatherType: number;
}

export class SnowVisibilitiesMenuTemperatureOption {
    Name: string;
    MaxTempC: number;
    DayNightOptions: SnowVisibilitiesMenuDayNightOption[];
}

export class SnowVisibilitiesMenuDayNightOption {
    Name: string;
    Values: SnowVisibilitiesMenuValue[];
}

export class SnowVisibilitiesMenuValue {
    Name: string;
    Miles: string;
    Meters: string;
    MetarWeatherType: number;
}

export class MetarMessageMapping {
    MetarWeatherType: number[];
    FluidType: string[];
    MaxTemp: number;
    Message: string;
    MobileMessage: string;
    ShowHotAndMessage: boolean;
    EquipmentTypeId?: number[];
}

export class FluidMetarSpec {
    FluidId: number;
    FluidVersionId: number;
    MetarWeatherTypeId: number;
    MinTemp: number;
    MaxTemp: number;
    MinHot: number;
    MaxHot: number;
    Notes: string;
}

export class MobileClientFluid {
    FluidId: number;
    FluidSeasonId: number;
    FluidVersionId: number;
    Name: string;
    Type: string;
    Dilution: string;
    Order: number;
    Cautions: string;
}

export abstract class PortalMobileClientConfiguration implements IResourceItem {
    abstract $save(): Promise<any>;
    abstract $delete(): Promise<any>;
    abstract $promise: Promise<any>;

    MobileClient: MobileClient;
    FluidSpecs: Array<FluidMetarSpec>;
    Weathers: Array<MobileWeatherInfo>;
    MessageMappings: Array<MetarMessageMapping>;
}

@Injectable({ providedIn: 'root' })
export class ApiService extends ExportApi {
    constructor(
        private apiFactory: ApiFactoryService,
    ) {
        super();
    }

    Permission = this.apiFactory.enumResourceFactory('/Permission');
    DeicingRecordFormat = this.apiFactory.enumResourceFactory('/DeicingRecordFormat');
    DeicingRecordTimeZone = this.apiFactory.enumResourceFactory('/DeicingRecordTimeZone');
    SubjectType = this.apiFactory.enumResourceFactory('/SubjectType');
    TemperatureUnit = this.apiFactory.enumResourceFactory('/TemperatureUnit');
    VolumeUnit = this.apiFactory.enumResourceFactory('/VolumeUnit');
    FluidType = this.apiFactory.enumResourceFactory('/FluidType');
    DeicingServiceType = this.apiFactory.enumResourceFactory('/DeicingServiceType');
    DeicingUserLogoSource = this.apiFactory.enumResourceFactory('/DeicingUserLogoSource');
    ChangeType = this.apiFactory.enumResourceFactory('/ChangeType');
    
    Change = this.apiFactory.resourceFactory<any>('/Change');
    ChangeExport = this.apiFactory.resourceFactory<any>('/ChangeExport');
    ChangeAlert = this.apiFactory.resourceFactory<any>('/ChangeAlert');
    User = this.apiFactory.resourceFactory<any>('/User');
    UserExport = this.apiFactory.resourceFactory<any>('/UserExport');
    ServiceProvider = this.apiFactory.resourceFactory<any>('/ServiceProvider');
    ServiceProviderExport = this.apiFactory.resourceFactory<any>('/ServiceProviderExport');
    DeicingFormConfiguration = this.apiFactory.resourceFactory<any>('/DeicingFormConfiguration');
    DeicingFormConfigurationExport = this.apiFactory.resourceFactory<any>('/DeicingFormConfigurationExport');
    ServiceProviderApi = this.apiFactory.resourceFactory<any>('/ServiceProviderApi');
    Airport = this.apiFactory.resourceFactory<any>('/Airport');
    Airline = this.apiFactory.resourceFactory<any>('/Airline');
    AirlineExport = this.apiFactory.resourceFactory<any>('/AirlineExport');
    Client = this.apiFactory.resourceFactory<any>('/Client');
    ClientExport = this.apiFactory.resourceFactory<any>('/ClientExport');
    DeicingFluid = this.apiFactory.resourceFactory<any>('/DeicingFluid');
    DeicingFluidExport = this.apiFactory.resourceFactory<any>('/DeicingFluidExport');
    DeicingRecord = this.apiFactory.resourceFactory<any>('/DeicingRecord');
    DeicingRecordTruckQuantity = this.apiFactory.resourceFactory<any>('/DeicingRecordTruckQuantity');
    DeicingRecordExport = this.apiFactory.resourceFactory<any>('/DeicingRecordExport');
    DeicingRecordDataUpload = this.apiFactory.resourceFactory<any>('/DeicingRecordDataUpload');
    DeicingRecordSignature = this.apiFactory.resourceFactory<any>('/DeicingRecordSignature');
    DeicingRecordTruckQuantitySignature = this.apiFactory.resourceFactory<any>('/DeicingRecordTruckQuantitySignature');
    PasswordResetToken = this.apiFactory.resourceFactory<any>('/PasswordResetToken');
    ResetPassword = this.apiFactory.resourceFactory<any>('/ResetPassword');
    CoordinatorDeicingRequest = this.apiFactory.resourceFactory<any>('/CoordinatorDeicingRequest');
    CoordinatorDeicingRequestExport = this.apiFactory.resourceFactory<any>('/CoordinatorDeicingRequestExport');
    CoordinatorHotGraphHot = this.apiFactory.resourceFactory<any>('/CoordinatorHotGraphHot');
    CoordinatorClientAirport = this.apiFactory.resourceFactory<CoordinatorClientAirport>('/CoordinatorClientAirport');
    CoordinatorFluid = this.apiFactory.resourceFactory<Fluid>('/CoordinatorFluid');
    CoordinatorAirline = this.apiFactory.resourceFactory<CoordinatorAirline>('/CoordinatorAirline');
    CoordinatorAirport = this.apiFactory.resourceFactory<CoordinatorAirport>('/CoordinatorAirport');
    CoordinatorMobileClient = this.apiFactory.resourceFactory<PortalMobileClientConfiguration>('/CoordinatorMobileClient');
    DeicingMobileConfiguration = this.apiFactory.resourceFactory<any>('/DeicingMobileConfiguration');
    DeicingMobileConfigurationExport = this.apiFactory.resourceFactory<any>('/DeicingMobileConfigurationExport');
    DeicingAirlineDeicingServiceProviderExport = this.apiFactory.resourceFactory<any>('/DeicingAirlineDeicingServiceProviderExport');
    DeicingServiceProviderDeicingAirlineExport = this.apiFactory.resourceFactory<any>('/DeicingServiceProviderDeicingAirlineExport');
    CoordinatorHotRequest = this.apiFactory.resourceFactory<any>('/CoordinatorHotRequest');
    FlightRecord = this.apiFactory.resourceFactory<any>('/FlightRecord');
    override ExcelExportTask = this.apiFactory.resourceFactory<any>('/ExcelExportTask');
    override ExcelExportResult = this.apiFactory.resourceFactory<any>('/ExcelExportResult');
    DeicingUserAuthentication = this.apiFactory.resourceFactory<any>('/DeicingUserAuthentication');
    DeicingUserAuthenticationExport = this.apiFactory.resourceFactory<any>('/DeicingUserAuthenticationExport');
    DeicingVehicleSafetyInspectionForm = this.apiFactory.resourceFactory<any>('/DeicingVehicleSafetyInspectionForm');
    TruckInfo = this.apiFactory.resourceFactory<any>('/TruckInfo');
    TruckInfoExport = this.apiFactory.resourceFactory<any>('/TruckInfoExport');
    TruckInfoSignature = this.apiFactory.resourceFactory<any>('/TruckInfoSignature');
    DeicingRecordSASAccountingReport = this.apiFactory.resourceFactory<any>('/DeicingRecordSASAccountingReport');
    DeicingRecordImportLog = this.apiFactory.resourceFactory<any>('/DeicingRecordImportLog');
    DeicingRecordImportLogExport = this.apiFactory.resourceFactory<any>('/DeicingRecordImportLogExport');
    DeicingRecordUploadLog = this.apiFactory.resourceFactory<any>('/DeicingRecordUploadLog');
    DeicingRecordUploadLogExport = this.apiFactory.resourceFactory<any>('/DeicingRecordUploadLogExport');
    TicketTrackerIssuesController = this.apiFactory.resourceFactory<any>('/TicketTrackerIssues');
}
