import {Component, ViewChild, Input, OnInit, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { TranslateService, ConfirmService, FilterPipe, DialogService, GridComponent } from 'swx.front-end-lib';
import { HasPermissionService } from '../shared/HasPermission.pipe';

@Component({
    templateUrl: 'UserDetail.component.html'
})
export class UserDetailComponent implements OnInit {
    item;
    returnPath;
    permissions = this.api.Permission.query();
    serviceProviders = this.api.ServiceProvider.query();
    airlines = this.api.Airline.query();
    deicingUserLogoSources = this.api.DeicingUserLogoSource.query();
    RepeatPassword;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    deicingServiceProvider;
    serviceProviderSearchQuery;
    deicingAirline;
    airlineSearchQuery;
    serviceProvidersColumnDefs = null;
    @ViewChild('serviceProvidersGrid', { static: false }) serviceProvidersGrid: GridComponent;
    airlinesColumnDefs = null;
    @ViewChild('airlinesGrid', { static: false }) airlinesGrid: GridComponent;
    sureWxDomainRegex = new RegExp('@surewx.com$');

    ticketTrackers = ['Gemini', 'YouTrack'];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        public hasPermissionService: HasPermissionService,
        private dialogService: DialogService,
    ) {
    }

    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.User.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                delete this.item.Name;
                delete this.item.Username;
                delete this.item.Email;
                delete this.item.Password;
                delete this.RepeatPassword;
                delete this.item.Comments;

                if (this.item.DeicingUserDeicingServiceProviders) {
                    this.item.DeicingUserDeicingServiceProviders.forEach(related => {
                        delete related.DeicingUserId;
                    });
                }

                if (this.item.DeicingUserDeicingAirlines) {
                    this.item.DeicingUserDeicingAirlines.forEach(related => {
                        delete related.DeicingUserId;
                    });
                }
            });
            this.RepeatPassword = '__PASSWORD__';
        } else if (isNew) {
            this.item = this.api.User.create({
                AirlineId: null,
                Active: true,
                LoginRequired: true
            });
        } else {
            this.item = this.api.User.get({ id: id });
            this.RepeatPassword = '__PASSWORD__';
        }

        Promise.all([
            this.item.$promise,
            this.airlines.$promise,
            this.serviceProviders.$promise
        ]).then(() => {
            this.item.DeicingUserDeicingServiceProviders = this.item.DeicingUserDeicingServiceProviders || [];
            this.item.DeicingUserDeicingAirlines = this.item.DeicingUserDeicingAirlines || [];

            this.airlinesColumnDefs = [
                { "colId": "DeicingAirlineId", "field": "DeicingAirlineId", "headerName": "#", "width": 60, "filterType": "integer" },
                { "colId": "Name", "field": "Name", "headerName": "Name", "width": 300, "cellRenderer": c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigViewAirlines')) {
                        container.innerHTML += '<a href="/airlines/' + c.data.DeicingAirlineId + '" onclick="event.preventDefault();">' + c.value + '</a>';
                        container.querySelector('a').addEventListener('click',
                            e => {
                                if (this.ngForm.form.pristine) {
                                    this.router.navigateByUrl(e.target['getAttribute']('href'));
                                } else {
                                    this.confirmService.confirm(this.translateService.translate('Are you sure you want to discard your changes?')).then(() => {
                                        this.router.navigateByUrl(e.target['getAttribute']('href'));
                                    }).catch(() => {});
                                }
                            });
                    } else {
                        container.innerHTML = c.value;
                    }
                    return container;
                } },
                { "colId": "IATACode", "field": "IATACode", "headerName": "IATA code", "width": 120 },
                { "colId": "ICAOCode", "field": "ICAOCode", "headerName": "ICAO code", "width": 120 },
	            { "colId": "_spacer", "flex": 1, "excelIgnore": true },
                { "colId": "Actions", "field": "DeicingAirlineId", "headerName": "", "width": 50, "cellClass": "actions", "cellRenderer": c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigEditUsers')) {
                        container.innerHTML += '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>';
                        container.querySelector('.mdi-delete').addEventListener('click', () => this.removeDeicingUserDeicingAirline(c.data).then(() => c.api.setRowData(this.transformDeicingUserAirlines(this.item.DeicingUserDeicingAirlines))));
                    }
                    return container;
                }, "pinned": "right" },
            ];

            this.serviceProvidersColumnDefs = [
                { "colId": "DeicingServiceProviderId", "field": "DeicingServiceProviderId", "headerName": "#", "width": 60, "filterType": "integer" },
                { "colId": "Name", "field": "Name", "headerName": "Name", "width": 300, "cellRenderer": c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigViewServiceProviders')) {
                        container.innerHTML += '<a href="/serviceProviders/' + c.data.DeicingServiceProviderId + '" onclick="event.preventDefault();">' + c.value + '</a>';
                        container.querySelector('a').addEventListener('click',
                            e => {
                                if (this.ngForm.form.pristine) {
                                    this.router.navigateByUrl(e.target['getAttribute']('href'));
                                } else {
                                    this.confirmService.confirm(this.translateService.translate('Are you sure you want to discard your changes?')).then(() => {
                                        this.router.navigateByUrl(e.target['getAttribute']('href'));
                                    }).catch(() => {});
                                }
                            });
                    } else {
                        container.innerHTML = c.value;
                    }
                    return container;
                } },
                { "colId": "Code", "field": "Code", "headerName": "Upload mapping code", "width": 120 },
                { "colId": "AirportCode", "field": "AirportCode", "headerName": "Airport", "width": 150 },
	            { "colId": "Spacer", "field": "Id", "headerName": "", "cellRenderer": () => "", "flex": 1 },
                { "colId": "Actions", "field": "DeicingServiceProviderId", "headerName": "", "width": 50, "cellClass": "actions", "cellRenderer": c => {
                    if (c.data == null) return '';
                    var container = document.createElement('div');
                    if (this.hasPermissionService.hasPermission('ConfigEditUsers')) {
                        container.innerHTML += '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>';
                        container.querySelector('.mdi-delete').addEventListener('click', () => this.removeDeicingUserDeicingServiceProvider(c.data).then(() => c.api.setRowData(this.transformDeicingUserServiceProviders(this.item.DeicingUserDeicingServiceProviders))));
                    }
                    return container;
                }, "pinned": "right" },
            ];

            setTimeout(() => {
                this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
                this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
            });
        });

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }
    
    transformDeicingUserServiceProviders(items) {
        if (items == null) return items;
        items.forEach(x => {
            var serviceProvider = this.serviceProviders.find(a => a.Id === x.DeicingServiceProviderId) || {};
            x.Name = serviceProvider.Name;
            x.Code = serviceProvider.Code;
            x.AirportCode = serviceProvider.AirportCode;
        });

        return items;
    }

    transformDeicingUserAirlines(items) {
        if (items == null) return items;
        items.forEach(x => {
            var airline = this.airlines.find(a => a.Id === x.DeicingAirlineId);
            x.Name = airline.Name;
            x.IATACode = airline.IATACode;
            x.ICAOCode = airline.ICAOCode;
        });

        return items;
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['User'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    isPermissionEnabled(option) {
        const selected = (this.item.Permissions || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    togglePermission(option) {
        const selected = this.item.Permissions ? this.item.Permissions.split(', ') : [];
        const index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        this.item.Permissions = selected.join(', ');
        this.ngForm.form.markAsDirty();
    }

    serviceProvidersNotEnabled(deicingServiceProviders: any[], deicingUserDeicingServiceProviders: any[]) {
        if (!deicingServiceProviders || !deicingUserDeicingServiceProviders) return deicingServiceProviders;

        return deicingServiceProviders.filter(a => deicingUserDeicingServiceProviders.every(r => r.DeicingServiceProviderId !== a.Id as any));
    }
    
    showServiceProvidersPopup() {
        this.dialogService.show(this.viewContainerRef, ServiceProviderSelectionDialogComponent,
            {
                options: this.serviceProvidersNotEnabled(this.serviceProviders, this.item.DeicingUserDeicingServiceProviders),
                close: selectedIds => {
                    this.item.DeicingUserDeicingServiceProviders = this.item.DeicingUserDeicingServiceProviders || [];
                    selectedIds.forEach(id => this.item.DeicingUserDeicingServiceProviders.push({
                        DeicingServiceProviderId: id
                    }));
                    this.serviceProvidersGrid.gridApi.setRowData(this.transformDeicingUserServiceProviders(this.item.DeicingUserDeicingServiceProviders));
                    this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
                    this.ngForm.form.controls.serviceProvidersForm.markAsDirty();
                }
            },
            {
                title: 'Select service providers',
                width: '80%',
                height: window.innerHeight * 0.8,
                modal: true,
                dialogClass: 'flexDialog'
            });
    }

    removeDeicingUserDeicingServiceProvider(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingUserDeicingServiceProviders.splice(this.item.DeicingUserDeicingServiceProviders.indexOf(item), 1);

            if (item.DeicingServiceProviderId === this.item.PrimaryDeicingServiceProviderId) {
                this.item.PrimaryDeicingServiceProviderId = null;
            }

            this.ngForm.form.controls.serviceProvidersForm.updateValueAndValidity();
            this.ngForm.form.controls.serviceProvidersForm.markAsDirty();
        }).catch(() => {});
    }

    airlinesNotEnabled(deicingAirlines: any[], deicingUserDeicingAirlines: any[]) {
        if (!deicingAirlines || !deicingUserDeicingAirlines) return deicingAirlines;

        return deicingAirlines.filter(a => deicingUserDeicingAirlines.every(r => r.DeicingAirlineId !== a.Id as any));
    }

    showAirlinesPopup() {
        this.dialogService.show(this.viewContainerRef, AirlineSelectionDialogComponent,
            {
                options: this.airlinesNotEnabled(this.airlines, this.item.DeicingUserDeicingAirlines),
                close: selectedIds => {
                    this.item.DeicingUserDeicingAirlines = this.item.DeicingUserDeicingAirlines || [];
                    selectedIds.forEach(id => this.item.DeicingUserDeicingAirlines.push({
                        DeicingAirlineId: id
                    }));
                    this.airlinesGrid.gridApi.setRowData(this.transformDeicingUserAirlines(this.item.DeicingUserDeicingAirlines));
                    this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
                    this.ngForm.form.controls.airlinesForm.markAsDirty();
                }
            },
            {
                title: 'Select airlines',
                width: '80%',
                height: window.innerHeight * 0.8,
                modal: true,
                dialogClass: 'flexDialog'
            });
    }

    removeDeicingUserDeicingAirline(item) {
        return this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DeicingUserDeicingAirlines.splice(this.item.DeicingUserDeicingAirlines.indexOf(item), 1);
            this.ngForm.form.controls.airlinesForm.updateValueAndValidity();
            this.ngForm.form.controls.airlinesForm.markAsDirty();
        }).catch(() => {});
    }
}

@Component({
    template: `
<div class="options contentMargins">
    <label class="inline search">
        <span class="mdi mdi-magnify"></span>
        <input [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" name="searchQuery" (ngModelChange)="grid.gridApi.setRowData(filter(options, searchQuery));" />
    </label>
    <label class="inline">
        <input type="button" class="green" (click)="addSelected();" value="{{'Add selected airlines'|translate}}" />
    </label>
    <label class="inline">
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
    </label>
</div>
<config-grid #grid
             [columnDefs]="columnDefs"
             [rowData]="options">
</config-grid>
`
})
export class AirlineSelectionDialogComponent {
    @Input('container') container: JQuery;
    @Input('options') options;
    @Input('close') close;
    searchQuery;
    @ViewChild('grid', { static: false }) grid : GridComponent;
    columnDefs = [
        { "colId": "Id", "field": "Id", "headerName": "#", "width": 60, "filterType": "integer", "checkboxSelection": true },
        { "colId": "Name", "field": "Name", "headerName": "Name", "width": 300 },
        { "colId": "IATACode", "field": "IATACode", "headerName": "IATA code", "width": 120 },
        { "colId": "ICAOCode", "field": "ICAOCode", "headerName": "ICAO code", "width": 120 },
    ];

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);
    
    addSelected() {
        var selectedIds = this.grid.gridApi.getSelectedRows()
            .map(row => row.Id);

        this.close(selectedIds);
        this.container.dialog('close');
    }

    cancel() {
        this.container.dialog('close');
    }
}

@Component({
    template: `
<div class="options contentMargins">
    <label class="inline search">
        <span class="mdi mdi-magnify"></span>
        <input [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" name="searchQuery" (ngModelChange)="grid.gridApi.setRowData(filter(options, searchQuery));" />
    </label>
    <label class="inline">
        <input type="button" class="green" (click)="addSelected();" value="{{'Add selected service providers'|translate}}" />
    </label>
    <label class="inline">
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
    </label>
</div>
<config-grid #grid
             [columnDefs]="columnDefs"
             [rowSelection]="'multiple'"
             [rowData]="options">
</config-grid>
`
})
export class ServiceProviderSelectionDialogComponent {
    @Input('container') container: JQuery;
    @Input('options') options;
    @Input('close') close;
    searchQuery;
    @ViewChild('grid', { static: false }) grid : GridComponent;
    columnDefs = [
        { "colId": "Id", "field": "Id", "headerName": "#", "width": 60, "filterType": "integer", "checkboxSelection": true },
        { "colId": "Name", "field": "Name", "headerName": "Name", "width": 300 },
        { "colId": "AirportCode", "field": "AirportCode", "headerName": "Airport", "width": 150 },
    ];

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);
    
    addSelected() {
        var selectedIds = this.grid.gridApi.getSelectedRows()
            .map(row => row.Id);

        this.close(selectedIds);
        this.container.dialog('close');
    }

    cancel() {
        this.container.dialog('close');
    }
}
