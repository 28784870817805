<div class="noMargins">
    <page-title>{{'Deicing Record Import Log' | translate}}</page-title>
    <form #form #ngForm="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="submit" [disabled]="!query.FromDate"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" [disabled]="!ngForm.form.valid || !query.FromDate" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
        </label>
    </form>

    <grid #grid
          gridId="preferences.DeicingRecord.ColumnExport_v13"
          [query]="query"
          [resource]="api.DeicingRecordImportLog"
          [exportResource]="api.DeicingRecordImportLogExport"
          [columnDefs]="columnDefs">
    </grid>
</div>
