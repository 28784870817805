<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Deicing record'|translate) + ': ' + (item.Id || 'new')}}</page-title>
    <div id="deicingRecordEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab active" [class.invalid]="!ngForm.form.valid">{{'Basic info'}}</a>
            <label>
                <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            </label>
            <label>
                <input type="button" (click)="saveCopy()" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save & copy'|translate}}" class="secondaryAction" />
            </label>
            <label>
                <input type="button" *ngIf="('DeicingAddRecord'|hasPermission)" (click)="reset()" value="{{'Reset'|translate}}" />
            </label>
            <label>
                <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
            </label>
        </div>

        <div class="form">
            <fieldset class="contentMargins" [disabled]="item.Id ? !('DeicingEditRecord'|hasPermission) : !('DeicingAddRecord'|hasPermission)">
                <ng-container *ngFor="let field of deicingFormFields | orderBy:'Order'">
					<ng-container *ngIf="field.Visible" [ngSwitch]="field.Id">
						<!--ServiceProviderId-->
						<ng-container *ngSwitchCase="'ServiceProviderId'">
							<label *ngIf="serviceProviders.length > 1">
								<span class="caption">{{field.Name}}</span>
								<select [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [selectedIndex]="-1" (ngModelChange)="selectServiceProvider()">
									<option *ngFor="let option of serviceProviders|orderBy:'Name'" [ngValue]="option.Id">
										{{option.Name}}
									</option>
								</select>
							</label>
						</ng-container>
						<!--AirlineId-->
						<ng-container *ngSwitchCase="'AirlineId'">
							<ng-container *ngIf="airlines.length > 1">
								<label>
									<span class="caption">{{field.Name}}</span>
									<select [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required && !item.AirlineName" [selectedIndex]="-1" (ngModelChange)="checkRegistrationPrefixes(); checkFlightRecords();">
										<option [ngValue]="null">
											{{'Other'|translate}}
										</option>
										<option *ngFor="let option of airlines|orderBy:'Name'" [ngValue]="option.Id">
											{{option.Name}}
										</option>
									</select>
								</label>
								<label *ngIf="item[field.Id] === null">
									<span class="caption">{{'Other airline'|translate}}</span>
									<input type="text" [(ngModel)]="item.AirlineName" name="AirlineName" [required]="field.Required" />
								</label>
							</ng-container>
						</ng-container>
						<!--AirportId-->
						<ng-container *ngSwitchCase="'AirportId'">
							<label *ngIf="airports.length > 1">
								<span class="caption">{{field.Name}}</span>
								<select [(ngModel)]="item[field.Id]" (ngModelChange)="airportChanged($event); checkFlightRecords();" [name]="field.Id" [required]="field.Required" [selectedIndex]="-1">
									<option *ngFor="let option of filterServiceProviderAirports(airports)|orderBy:'ICAOCode'" [ngValue]="option.Id">
										{{option.ICAOCode + ' - ' + option.Name}}
									</option>
								</select>
								<span style="margin-top: 5px; margin-left: 5px;">{{'Time zone: ' + timeZone}}</span>
							</label>
						</ng-container>
						<!--DeicingServiceProviderFluidId or AntiIcingServiceProviderFluidId-->
						<ng-container *ngSwitchCase="field.Id == 'DeicingServiceProviderFluidId' || field.Id == 'AntiIcingServiceProviderFluidId' ? field.Id : null">
							<label>
								<span class="caption">{{field.Name}}</span>
								<select [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [selectedIndex]="-1">
									<ng-container *ngIf="item.ServiceProviderId">
										<option [ngValue]="null">
											{{'Other'|translate}}
										</option>
										<option *ngFor="let option of (serviceProviders|findById:item.ServiceProviderId).DeicingFluids|orderBy:'Name'" [ngValue]="option.Id">
											{{fluidName(option)}}
										</option>
									</ng-container>
								</select>
								<label *ngIf="item[field.Id] === null && field.Id === 'DeicingFluidId'">
									<span class="caption">{{'Other fluid'|translate}}</span>
									<input type="text" [(ngModel)]="item.DeicingFluidName" name="DeicingFluidName" [required]="field.Required" />
								</label>
								<label *ngIf="item[field.Id] === null && field.Id === 'AntiIcingFluidId'">
									<span class="caption">{{'Other fluid'|translate}}</span>
									<input type="text" [(ngModel)]="item.AntiIcingFluidName" name="AntiIcingFluidName" [required]="field.Required" />
								</label>
							</label>
						</ng-container>
						<!--HoldoverStartTime or DeicingCompleteTime or WheelsUpTime or ScheduledDepartureTime or OffBlockTime or DeicingStartTime or AntiicingCompleteTime -->
						<label *ngSwitchCase="field.Id == 'HoldoverStartTime' || field.Id == 'DeicingCompleteTime' || field.Id == 'WheelsUpTime' || field.Id == 'ScheduledDepartureTime' || field.Id == 'OffBlockTime' || field.Id == 'DeicingStartTime' || field.Id == 'AntiicingCompleteTime' ? field.Id : null">
							<span class="caption">{{field.Name}}</span>
							<input date-time [options]="datePickerOptions" [validator]="datePickerValidator" [required]="field.Required" [(ngModel)]="item[field.Id]" [name]="field.Id" [timeZone]="timeZone" (ngModelChange)="checkFlightRecords()" />
							<span style="margin-top: 5px; margin-left: 5px;" *ngIf="item.AirportId">{{'(Airport local time)'}}</span>
						</label>
						<!--All Multiselect fields-->
						<div class="field" *ngSwitchCase="field.Multiselect ? field.Id : null">
							<span class="caption">{{field.Name}}</span>
							<multiselect [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [all]="false" [format]="'string'">
								<multiselect-option *ngFor="let option of field.Options" [value]="option" [name]="option"></multiselect-option>
							</multiselect>
						</div>
						<!--All Select fields-->
						<div class="field" *ngSwitchCase="field.Select ? field.Id : null">
							<span class="caption">{{field.Name}}</span>
							<select [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required && field.Options && field.Options.length > 0" [selectedIndex]="-1">
								<option *ngFor="let option of (field.Options || [])" [ngValue]="option">
									{{option}}
								</option>
							</select>
						</div>
						<!--RegistrationNumber-->
						<div class="field" *ngSwitchCase="'RegistrationNumber'">
							<span class="caption">{{field.Name}}</span>
							<select *ngIf="registrationPrefixes.length !== 0 && (registrationPrefixes.length > 1 || (airlines|findById:item.AirlineId)?.AircraftIdentificationUneditablePrefix)" [(ngModel)]="selectedRegistrationPrefix" [name]="regPrefix" (ngModelChange)="item[field.Id] = selectedRegistrationPrefix.trim();" [ngModelOptions]="{standalone: true}">
								<option *ngFor="let option of registrationPrefixes" [ngValue]="option">
									{{option}}
								</option>
							</select>
							<autocomplete type="text" [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [options]="registrationNumbers" [placeholder]="(airlines|findById:item.AirlineId)?.AircraftIdentificationPlaceholder" [pattern]="registrationPattern" (keyup)="checkRegistrationFormat($event, field)" (ngModelChange)="checkRegistrationFormat($event, field)"></autocomplete>
						</div>
						<!--FlightNumber-->
						<div class="field" *ngSwitchCase="'FlightNumber'">
							<span class="caption">{{field.Name}}</span>
							<autocomplete type="text" [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [options]="flightNumbers" (ngModelChange)="checkFlightRecords()"></autocomplete>
						</div>
						<!--EquipmentTypeIdentifier-->
						<div class="field" *ngSwitchCase="'EquipmentTypeIdentifier'">
							<span class="caption">{{field.Name}}</span>
							<autocomplete type="text" [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [options]="equipmentTypeIdentifiers" (ngModelChange)="checkFlightRecords()"></autocomplete>
						</div>
						<!--All Autocomplete except RegistrationNumber-->
						<div class="field" *ngSwitchCase="field.Autocomplete && field.Id != 'RegistrationNumber' ? field.Id : null">
							<span class="caption">{{field.Name}}</span>
							<autocomplete type="text" [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [options]="field.Options"></autocomplete>
						</div>
						<!--SprayCount-->
						<label *ngSwitchCase="'SprayCount'">
							<span class="caption">{{field.Name}}</span>
							<input type="number" [(ngModel)]="item[field.Id]" [name]="field.Id" min="0" step="1" [required]="field.Required" />
						</label>
						<!--Type1MixPercent-->
						<label *ngSwitchCase="'Type1MixPercent'">
							<span class="caption">{{field.Name}}</span>
							<input type="number" [(ngModel)]="item[field.Id]" [name]="field.Id" min="0" max="100" step="0.01" [required]="field.Required" />
						</label>
						<!--Type1Volume-->
						<label *ngSwitchCase="'Type1Volume'">
							<span class="caption">{{field.Name}} {{field.UseGallons ? '(gal)' : '(L)' }}</span>
							<input type="number" [(ngModel)]="item[field.Id + (field.UseGallons ? 'Gallons' : 'Litres')]" [name]="field.Id" min="0" step="0.01" [required]="field.Required" />
						</label>
						<!--Type1Brix-->
						<label *ngSwitchCase="'Type1Brix'">
							<span class="caption">{{field.Name}}</span>
							<input type="number" [(ngModel)]="item[field.Id]" [name]="field.Id" min="0" step="0.01" [required]="field.Required" />
						</label>
						<!--Type4MixPercent-->
						<label *ngSwitchCase="'Type4MixPercent'">
							<span class="caption">{{field.Name}}</span>
							<input type="number" [(ngModel)]="item[field.Id]" [name]="field.Id" min="0" max="100" step="0.01" [required]="field.Required" />
						</label>
						<!--Type4Volume-->
						<label *ngSwitchCase="'Type4Volume'">
							<span class="caption">{{field.Name}} {{field.UseGallons ? '(gal)' : '(L)' }}</span>
							<input type="number" [(ngModel)]="item[field.Id + (field.UseGallons ? 'Gallons' : 'Litres')]" [name]="field.Id" min="0" step="0.01" [required]="field.Required" />
						</label>
						<!--Type4Brix-->
						<label *ngSwitchCase="'Type4Brix'">
							<span class="caption">{{field.Name}}</span>
							<input type="number" [(ngModel)]="item[field.Id]" [name]="field.Id" min="0" step="0.01" [required]="field.Required" />
						</label>
						<!--PassengerLoad-->
						<label *ngSwitchCase="'PassengerLoad'">
							<span class="caption">{{field.Name}}</span>
							<input type="number" [(ngModel)]="item[field.Id]" [name]="field.Id" min="0" step="1" [required]="field.Required" />
						</label>
						<!--OAT-->
						<label *ngSwitchCase="'OAT'">
							<span class="caption">{{field.Name}} {{field.UseFahrenheit ? '(&deg;F)' : '(&deg;C)' }}</span>
							<input type="number" [(ngModel)]="item[field.Id + (field.UseFahrenheit ? 'Fahrenheit' : 'Celsius')]" [name]="field.Id" step="0.01" [required]="field.Required" />
						</label>
						<!--TruckNumber -->
						<ng-container *ngSwitchCase="'TruckNumber'">
							<label *ngIf="vehicles.length > 1">
								<span class="caption">{{field.Name}}</span>
								<select [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [selectedIndex]="-1">
									<option *ngFor="let option of vehicles|orderBy:'ManufacturerAndModelCategory'" [ngValue]="option.ManufacturerAndModelCategory">
										{{option.ManufacturerAndModelCategory}}
									</option>
								</select>
							</label>
						</ng-container>
						<!--TruckDriver || TruckOperator  -->
						<ng-container *ngSwitchCase="field.Id === 'TruckDriver' || field.Id === 'TruckOperator' ? field.Id : null">
							<label *ngIf="vehicleOperators.length >= 1">
								<span class="caption">{{field.Name}}</span>
								<select [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" [selectedIndex]="-1">
									<option *ngFor="let option of vehicleOperators|orderBy:'Name'" [ngValue]="option.Name">
										{{option.Name}}
									</option>
								</select>
							</label>
						</ng-container>
						<!-- Nozzle temperature check (not required on the web form) -->
						<ng-container *ngSwitchCase="'ConfirmNozzleTemperature'">
							<label>
								<span class="caption">{{field.Name}}</span>
								<input type="checkbox" [(ngModel)]="item[field.Id]" [name]="field.Id" />
							</label>
						</ng-container>

						<!-- HOT required? -->
                        <ng-container *ngSwitchCase="field.Id == 'IsHOTDeicing' || field.Id == 'CanBeCancelled' || field.Id == 'CanBeModified' ? field.Id : null">
                            <label>
                                <span class="caption">{{field.Name}}</span>
                                <input type="checkbox" [(ngModel)]="item[field.Id]" [name]="field.Id" [disabled]="requireHotIsDisabled()"/>
                            </label>
                        </ng-container>
					
						<!-- Number of steps -->
                        <ng-container *ngSwitchCase="'Steps'">
						    <label>
							    <span class="caption">{{field.Name}}</span>
							    <input type="radio" [ngModel]="item[field.Id]" (ngModelChange)="selectSteps(1)" [name]="field.Id" [value]="1" /> 1 Step
							    <input type="radio" [ngModel]="item[field.Id]" (ngModelChange)="selectSteps(2)" [name]="field.Id" [value]="2"/> 2 Steps
						    </label>
                        </ng-container>

                        <!--Default-->
						<label *ngSwitchDefault>
							<span class="caption">{{field.Name}}</span>
							<input type="text" [(ngModel)]="item[field.Id]" [name]="field.Id" [required]="field.Required" />
						</label>
					</ng-container>
                </ng-container>
            </fieldset>
        </div>
    </div>
</form>
