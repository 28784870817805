import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService, IResourceItemList } from 'swx.front-end-lib';
import { ApiService } from '../shared/Api.service';

@Injectable({ providedIn: 'root' })
export class RootScope {
    dateTimeFormat = 'YYYY-MM-DD HH:mm';
    dateFormat = 'YYYY-MM-DD';
    timeFormat = 'HH:mm';
    timeFormatLong = 'HH:mm:ss';
    currentUser = null;

    private serviceProviderSubject = new Subject<boolean>();
    serviceProviderChange = this.serviceProviderSubject.asObservable();

    private serviceProvidersPromise;
    private airlinesPromise;

    constructor(
        private authentication: AuthenticationService,
        private api: ApiService,
    ) {
        this.authentication.userChange.subscribe(user => {
            this.currentUser = user;
            this.serviceProvidersPromise = null;
            this.airlinesPromise = null;
            this.serviceProviderSubject.next(true);
        });
    }

    getServiceProviders(): Promise<IResourceItemList<any>> {
        if (this.serviceProvidersPromise == null) {
            this.serviceProvidersPromise = this.authentication.getCurrentUser().then(() => {
                return this.api.ServiceProvider.query().$promise;
            });
        }
        return this.serviceProvidersPromise;
    }

    getAirlines() {
        if (this.airlinesPromise == null) {
            this.airlinesPromise = this.authentication.getCurrentUser().then(() => {
                return this.api.Airline.query().$promise;
            });
        }
        return this.airlinesPromise;
    }

    // Temperature conversions
    celsiusToFahrenheit(celsiusTemp: number): number {
        return celsiusTemp * 1.8 + 32;
    }

    fahrenheitToCelsius(fahrenheitTemp: number): number {
        return (fahrenheitTemp - 32) * 5/9;
    }
}
