<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('User'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="userEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewUserServiceProviders'|hasPermission)" (click)="switchTab('serviceProviders')" [class.active]="tab == 'serviceProviders'" [class.invalid]="!ngForm.form.controls.serviceProvidersForm?.valid">{{'Service providers'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewUserAirlines'|hasPermission)" (click)="switchTab('airlines')" [class.active]="tab == 'airlines'" [class.invalid]="!ngForm.form.controls.airlinesForm?.valid">{{'Airlines'|translate}}</a>
			<label>
				<input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
			</label>
			<label>
				<input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
			</label>
			<label>
				<input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
			</label>
		</div>

		<fieldset class="contentMargins form" [disabled]="!('ConfigEditUsers'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Name'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Name" name="Name" />
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="Active" />
			</label>
			<label>
				<span class="caption">{{'Email'|translate}}</span>
				<input type="email" required [(ngModel)]="item.Email" name="Email" autocomplete="off" />
			</label>
			<label>
				<span class="caption">{{'Phone number'|translate}}</span>
				<input type="text" [(ngModel)]="item.PhoneNumber" name="PhoneNumber" autocomplete="off" />
			</label>
			<label>
				<span class="caption">{{'Cell number'|translate}}</span>
				<input type="text" [(ngModel)]="item.CellNumber" name="CellNumber" autocomplete="off" />
			</label>
			<label>
				<span class="caption">{{'User can log in?'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.LoginRequired" name="LoginRequired" />
			</label>
			<label *ngIf="item.LoginRequired">
				<span class="caption">{{'Username'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Username" name="Username" autocomplete="off" />
			</label>
			<label *ngIf="item.LoginRequired">
				<span class="caption">{{'Password'|translate}}</span>
				<input type="password" required [(ngModel)]="item.Password" name="Password" autocomplete="off" minlength="6" />
			</label>
			<label *ngIf="item.LoginRequired">
				<span class="caption">{{'Repeat password'|translate}}</span>
				<input type="password" required [(ngModel)]="RepeatPassword" name="RepeatPassword" autocomplete="off" [password-match]="item.Password" />
			</label>
			<label>
				<span class="caption">{{'Logo to show'|translate}}</span>
				<select [(ngModel)]="item.DeicingUserLogoSource" name="item.DeicingUserLogoSource" [selectedIndex]="-1">
					<option [ngValue]="null">{{'None'|translate}}</option>
					<option *ngFor="let option of deicingUserLogoSources|keys" [ngValue]="option">
						{{deicingUserLogoSources[option]}}
					</option>
				</select>
			</label>
			<label *ngIf="item.DeicingUserLogoSource === 'ServiceProvider' && item.DeicingUserDeicingServiceProviders.length > 1">
				<span class="caption">{{'Primary service provider'|translate}}</span>
				<select required [(ngModel)]="item.PrimaryDeicingServiceProviderId" name="item.PrimaryDeicingServiceProviderId">
					<option *ngFor="let option of item.DeicingUserDeicingServiceProviders" [ngValue]="option.DeicingServiceProviderId">
						{{(serviceProviders|findById:option.DeicingServiceProviderId)?.Name}}
					</option>
				</select>
			</label>
			<label *ngIf="item.DeicingUserLogoSource === 'Airline' && item.DeicingUserDeicingAirlines.length > 1">
				<span class="caption">{{'Primary airline'|translate}}</span>
				<select required [(ngModel)]="item.PrimaryDeicingAirlineId" name="item.PrimaryDeicingAirlineId">
					<option *ngFor="let option of item.DeicingUserDeicingAirlines" [ngValue]="option.DeicingAirlineId">
						{{(airlines|findById:option.DeicingAirlineId)?.Name}}
					</option>
				</select>
			</label>
			<label>
				<span class="caption">{{'Comments'|translate}}</span>
				<textarea [(ngModel)]="item.Comments" name="Comments" style="width: 400px; height: 75px;"></textarea>
			</label>
			<label *ngIf="sureWxDomainRegex.test(item.Email) || item.TicketTrackingRequired">
				<span class="caption">{{'Mandatory input of ticket and comment'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.TicketTrackingRequired" name="item.TicketTrackingRequired" />
			</label>
            <label *ngIf="item.TicketTrackingRequired">
                <span class="caption">{{'Ticket Tracker'|translate}}</span>
                <select [(ngModel)]="item.TicketTracker" name="item.TicketTracker">
                    <option *ngFor="let tracker of ticketTrackers">{{tracker}}</option>
                </select>
            </label>
			<label *ngIf="item.TicketTrackingRequired && item.TicketTracker === 'Gemini'">
				<span class="caption">{{'Ticket tracking username'|translate}}</span>
				<input type="text" [required]="item.TicketTrackingRequired" [(ngModel)]="item.TicketTrackingUsername" name="item.TicketTrackingUsername" />
			</label>
			<label *ngIf="item.TicketTrackingRequired">
				<span class="caption">{{'Ticket tracking api key'|translate}}</span>
				<input type="text" [required]="item.TicketTrackingRequired" [(ngModel)]="item.TicketTrackingApiKey" name="item.TicketTrackingApiKey" />
			</label>
			<div class="field">
				<span class="caption">{{'Permissions'|translate}}</span>
				<div required>
					<label *ngFor="let option of permissions|keys">
						<input type="checkbox" [checked]="isPermissionEnabled(option)" (click)="togglePermission(option); ngForm.form.markAsTouched();" />{{permissions[option]}}
					</label>
				</div>
			</div>
		</fieldset>

		<div class="flexTab" *ngIf="serviceProvidersColumnDefs != null" [style.display]="tab == 'serviceProviders' ? '' :'none'">
			<div class="options contentMargins">
				<label class="inline search">
					<span class="mdi mdi-magnify"></span>
					<input [(ngModel)]="serviceProviderSearchQuery" [ngModelOptions]="{standalone: true}" name="serviceProviderSearchQuery" (ngModelChange)="serviceProvidersGrid.gridApi.setRowData(filter(transformDeicingUserServiceProviders(item.DeicingUserDeicingServiceProviders), serviceProviderSearchQuery));" />
				</label>
				<label class="inline">
					<input type="button" class="green" *ngIf="('ConfigEditUsers'|hasPermission)" (click)="showServiceProvidersPopup();" value="{{'Add service providers...'|translate}}" />
				</label>
			</div>

            <config-grid #serviceProvidersGrid
                         class="tabContent"
                         ngModelGroup="serviceProvidersForm"
                         #serviceProvidersForm="ngModelGroup"
                         gridId="preferences.UserServiceProvider.ColumnExport"
                         [columnDefs]="serviceProvidersColumnDefs"
                         [rowData]="transformDeicingUserServiceProviders(item.DeicingUserDeicingServiceProviders)">
            </config-grid>
		</div>

		<div class="flexTab" *ngIf="airlinesColumnDefs != null" [style.display]="tab == 'airlines' ? '' :'none'">
			<div class="options contentMargins">
				<label class="inline search">
					<span class="mdi mdi-magnify"></span>
					<input [(ngModel)]="airlineSearchQuery" [ngModelOptions]="{standalone: true}" name="airlineSearchQuery" (ngModelChange)="airlinesGrid.gridApi.setRowData(filter(transformDeicingUserAirlines(item.DeicingUserDeicingAirlines), airlineSearchQuery));" />
				</label>
				<label class="inline">
					<input type="button" class="green" *ngIf="('ConfigEditUsers'|hasPermission)" (click)="showAirlinesPopup();" value="{{'Add airlines...'|translate}}" />
				</label>
			</div>

            <config-grid #airlinesGrid
                         class="tabContent"
                         ngModelGroup="airlinesForm"
                         #airlinesForm="ngModelGroup"
                         gridId="preferences.UserAirline.ColumnExport"
                         [columnDefs]="airlinesColumnDefs"
                         [rowData]="transformDeicingUserAirlines(item.DeicingUserDeicingAirlines)">
            </config-grid>
		</div>
	</div>
</form>
