<page-title>{{('Forgot password?'|translate)}}</page-title>
<div *ngIf="error">
    <h3 style="margin-top: 0;">{{'Your search did not return any results.'|translate}} <a routerLink="/login">{{'Click here'|translate}}</a> {{'to try again.'|translate}}</h3>
</div>
<div *ngIf="!error">
    <div *ngIf="sent">
        <h3 style="margin-top: 0;">{{'Instructions for resetting your password have been sent to your email address.'|translate}}</h3>
    </div>
    <div *ngIf="!sent">
        <h3 style="margin-top: 0;">{{'Please enter your email address to continue'|translate}}</h3>
        <form class="form" #form="ngForm" (submit)="submit()">
            <label>
                <input type="email" name="email" [(ngModel)]="email" required style="width: 300px;" />
            </label>
            <br />
            <input type="submit" [disabled]="!form.form.valid" value="{{'Send email'|translate}}" />&nbsp;&nbsp;&nbsp;&nbsp;<a routerLink="/login">{{'Cancel'}}</a>
        </form>
    </div>
</div>