import {Injectable, Component, Input, OnInit, ViewContainerRef} from '@angular/core'
import { IResourceItem, DialogService, AuthenticationService, TranslateService, NotificationService, BusyIndicatorService } from 'swx.front-end-lib';
import { Router } from '@angular/router';
import { ApiService } from '../shared/Api.service';

@Injectable()
export class TicketTrackingSystemService {
    constructor(
        private dialogService: DialogService,
        private authentication: AuthenticationService,
        private router: Router,
        private translateService: TranslateService,
        private notification: NotificationService,
        private api: ApiService,
    ) {
    }

    getComment(viewContainerRef: ViewContainerRef): Promise<TicketTrackingCommentInfo> {
        return new Promise<TicketTrackingCommentInfo>((resolve, reject) => {
            this.dialogService.show(viewContainerRef, TicketTrackingCommentDialogComponent,
                {
                    close: resolve
                },
                {
                    title: 'Comments',
                    width: 435,
                    modal: true
                });
        });
    }

    trackAndSave(viewContainerRef: ViewContainerRef, item: IResourceItem, returnPath: string) {
        return this.authentication.getCurrentUser().then(currentUser => {
            if (currentUser.TicketTrackingRequired) {
                return this.getComment(viewContainerRef)
                    .then(result => {
                        return item.$save({}, {
                            comment: result.Comment,
                            ticketNumber: result.TicketNumber,
                        }).then(saveResult => {
                            this.notification.show(this.translateService.translate('The changes have been saved and the ticket comment has been added.'));
                            this.router.navigateByUrl(returnPath);
                            return saveResult;
                        });
                    });
            } else {
                return item.$save().then(saveResult => {
                    this.notification.show(this.translateService.translate('The changes have been saved.'));
                    this.router.navigateByUrl(returnPath);
                    return saveResult;
                });
            }
        });
        
    }

    trackAndDelete(viewContainerRef: ViewContainerRef, item: IResourceItem, callback: () => any) {
        return this.authentication.getCurrentUser().then(currentUser => {
            if (currentUser.TicketTrackingRequired) {
                return this.getComment(viewContainerRef)
                    .then(result => {
                        return item.$delete({}, {
                            comment: result.Comment,
                            ticketNumber: result.TicketNumber,
                        }).then(deleteResult => {
                            this.notification.show(this.translateService.translate('The item has been deleted and the ticket comment has been added.'));
                            callback();
                            return(deleteResult);
                        });
                    });
            } else {
                return item.$delete().then(deleteResult => {
                    this.notification.show(this.translateService.translate('The item has been deleted.'));
                    callback();
                    return(deleteResult);
                });
            }
        });
    }

    getIssues(searchTerm): Promise<Issue[]> {
        return this.api.TicketTrackerIssuesController.query({ searchText: searchTerm }).$promise as Promise<Issue[]>;
    }
}

interface Issue {
    Id: string;
    Description: string;
}

@Component({
    template: `<form class="form" #form #ngForm="ngForm" (submit)="save()">
    <fieldset class="contentMargins">
        <label>
            <span class="caption">{{'Issue Id'|translate}}</span>
            <autocomplete required [(ngModel)]="ticketNumber" [autocompleteOptions]="autocompleteOptions" name="ticketNumber"></autocomplete>
        </label>
        <label>
            <span class="caption">{{'Comments'|translate}}</span>
            <textarea required [(ngModel)]="comments" name="comments" style="width: 400px; height: 75px;"></textarea>
        </label>
        <label>
            <input type="submit" (click)="save()" [disabled]="!ngForm.form.valid" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        </label>
    </fieldset>
</form>`
})
export class TicketTrackingCommentDialogComponent implements  OnInit {
    @Input('container') container: JQuery;
    @Input('close') close: (commentInfo: TicketTrackingCommentInfo) => any;

    ticketNumber: string = '';
    comments: string = '';
    autocompleteOptions: JQueryUI.AutocompleteOptions;

    constructor(private ticketTrackingSystem: TicketTrackingSystemService, private busyIndicator: BusyIndicatorService) {}

    ngOnInit(): void {
        this.autocompleteOptions = {
            minLength: 2,
            delay: 1000,
            source: (request, response) => {
                this.busyIndicator.setBusy(true);
                this.ticketTrackingSystem.getIssues(request.term)
                    .then(issues => {
                        const suggestions = issues.map(issue => ({
                            label: issue.Id + " | " + issue.Description,
                            value: issue.Id
                        }));

                        response(suggestions);
                    })
                    .catch(() => {
                        response([]);
                    })
                    .finally(() => this.busyIndicator.setBusy(false));
            }
        };
    }

    save() {
        this.close({
            TicketNumber: this.ticketNumber,
            Comment: this.comments
        });
        this.container.dialog('close');
    }

    cancel() {
        this.container.dialog('close');
    }
}

class TicketTrackingCommentInfo {
    TicketNumber: string;
    Comment: string;
}
