<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Deicing form configuration'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="deicingFormConfigurationEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
			<a class="tab" (click)="switchTab('deicingForm')" [class.active]="tab == 'deicingForm'" [class.invalid]="!ngForm.form.controls.deicingForm?.valid">{{'Form fields'|translate}}</a>
			<label>
				<input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
			</label>
			<label>
				<input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
			</label>
			<label>
				<input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
			</label>
		</div>

		<fieldset [disabled]="!('ConfigEditDeicingFormConfigurations'|hasPermission)" class="contentMargins form" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Name'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Name" name="Name" maxlength="100" />
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="Active" />
			</label>
		</fieldset>

		<fieldset [disabled]="!('ConfigEditDeicingFormConfigurations'|hasPermission)" 
				  class="contentMargins form" 
				  [style.display]="tab == 'deicingForm' ? '' :'none'" 
				  ngModelGroup="deicingForm">
			<sortable class="items">
				<sortable-item *ngFor="let field of item.DeicingFormFields | orderBy:'Order'"
							   class="item"
							   [(ngModel)]="field.Order"
							   name="Order[{{field.Id}}]"
							   [class.selected]="field.selected">
					<label>
						<span class="mdi mdi-adjust"></span>
					</label>
					<label>
						<input type="text"
							   required
							   [(ngModel)]="field.Name"
							   name="Name[{{field.Id}}]"
							   [placeholder]="field.Placeholder" />
					</label>
					<label *ngIf="field.Optional">
						<span class="caption">{{'Visible'|translate}}</span>
						<input type="checkbox"
							   [(ngModel)]="field.Visible"
							   name="Visible[{{field.Id}}]" />
					</label>
					<label *ngIf="field.Optional && field.Visible">
						<span class="caption">{{'Required'|translate}}</span>
						<input type="checkbox"
							   [(ngModel)]="field.Required"
							   name="Required[{{field.Id}}]" />
					</label>
					<!-- Temperatures can be in Fahrenheit -->
					<label *ngIf="field.UseFahrenheit !== undefined && field.Optional && field.Visible">
						<span class="caption">{{'Use &deg;F'|translate}}</span>
						<input type="checkbox"
							   [(ngModel)]="field.UseFahrenheit"
							   name="UseFahrenheit[{{field.Id}}]" />
					</label>
					<!-- Volumes can be in gallons -->
					<label *ngIf="field.UseGallons !== undefined && field.Optional && field.Visible">
						<span class="caption">{{'Use gallons'|translate}}</span>
						<input type="checkbox"
							   [(ngModel)]="field.UseGallons"
							   name="UseGallons[{{field.Id}}]" />
					</label>

					<label *ngIf="field.Visible && (field.Select || field.Multiselect)">
						<span class="caption">{{'Options'|translate}}</span>
						<input type="text"
							   readonly
							   [value]="(field?.Options || []).join(', ')" />
						<input type="button"
							   (click)="editOptions(field);"
							   value="{{'Edit'|translate}}" />
					</label>
				</sortable-item>
			</sortable>
		</fieldset>
	</div>
</form>
