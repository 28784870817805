<page-title>{{('Reset password'|translate)}}</page-title>
<div *ngIf="error">
    <h3 style="margin-top: 0;">{{'Your search did not return any results.'|translate}} <a routerLink="/login">{{'Click here'|translate}}</a> {{'to try again.'|translate}}</h3>
</div>
<div *ngIf="!error">
    <div *ngIf="sent">
        <h3 style="margin-top: 0;">{{'Your password has been changed.'|translate}} <a routerLink="/login">{{'Click here'|translate}}</a> {{'to log in.'|translate}}</h3>
    </div>
    <div *ngIf="!sent">
        <h3 style="margin-top: 0;">{{'Please enter your new password'|translate}}</h3>
        <form class="form" #form="ngForm" (submit)="submit()">
            <label>
                <span class="caption">{{'New password'|translate}}</span>
                <input type="password" required [(ngModel)]="password" name="password" minlength="6" />
            </label>
            <label>
                <span class="caption">{{'Repeat password'|translate}}</span>
                <input type="password" required [(ngModel)]="repeatPassword" name="repeatPassword" [password-match]="password" />
            </label>
            <br />
            <input type="submit" [disabled]="!form.form.valid" value="{{'Change password'|translate}}" />
        </form>
    </div>
</div>