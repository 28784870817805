<div id="deicingFormConfigurationList" class="noMargins">
	<page-title>{{('Deicing form configuration'|translate)}}</page-title>
	<div class="toolbar gridFilters">
		<form #form #ngForm="ngForm" (submit)="refresh()">
            <label class="inline">
                <span class="caption">{{'Search'|translate}}</span>
                <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
            </label>
            <label class="inline">
                <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
                <span class="caption">{{'Include inactive'|translate}}</span>
            </label>
			<label>
				<a *ngIf="'ConfigEditDeicingFormConfigurations'|hasPermission" routerLink="/deicingFormConfigurations/new"><button type="button">{{'Add deicing form configuration'|translate}}</button></a>
			</label>
			<filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
			<label class="inline">
				<span class="caption">&nbsp;</span>
				<button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
			</label>
			<label class="inline">
				<span class="caption">&nbsp;</span>
				<button type="submit" [disabled]="!ngForm.form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
			</label>
            <label class="inline">
                <span class="caption">&nbsp;</span>
                <button type="button" [disabled]="!ngForm.form.valid" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
            </label>
		</form>
	</div>

    <config-grid #grid
          gridId="preferences.DeicingFormConfiguration.ColumnExport"
          [query]="query"
          [resource]="api.DeicingFormConfiguration"
          [exportResource]="api.DeicingFormConfigurationExport"
          [columnDefs]="columnDefs">
    </config-grid>
</div>
