<span class="pull-right">{{'*Timestamps are related to the time of weather observation'|translate}}</span>
<table class="table table-striped table-hover table-bordered hotSize">
    <tr>
        <th class="col-md-1">{{'Date'|translate}}</th>
        <th class="col-md-1">{{'Request time'|translate}}</th>
        <th class="col-md-1">{{'Request type'|translate}}</th>
        <th class="col-md-1">{{'Requested by'|translate}}</th>
        <th class="col-md-1">{{'Response type'|translate}}</th>
        <th *ngFor="let fluid of fluids">{{fluid.ClientFluidName + (fluid.FluidTypeNumber === 1 ? '' : (' ' + fluid.Dilution)) + ' (Type ' + fluid.FluidTypeNumber + ')'}}</th>
        <th class="col-md-1">{{'*Weather Timestamp'|translate}}</th>
        <th class="col-md-1">{{'Temperature'|translate}}</th>
        <th class="col-md-2">{{'Weather'|translate}}</th>
    </tr>
    <tr *ngFor="let historyHot of hotRequests">
        <td class="col-md-1">{{moment.utc(historyHot.RequestDateTime).format($root.dateFormat)}}</td>
        <td class="col-md-1">{{moment.utc(historyHot.RequestDateTime).format($root.timeFormat)}} {{historyHot.SuffixDate}}</td>
        <td class="col-md-1">{{historyHot.RequestType}}</td>
        <td class="col-md-1">{{historyHot.TailNumber || historyHot.FlightIdentifier || historyHot.PortalUserEmail || historyHot.IPAddress}}</td>
        <td class="col-md-1">{{historyHot.ResponseType}}</td>
        <td class="info" *ngFor="let fluid of fluids">{{historyHot.HotResponseHots?.length > 0 ? findHot(historyHot.HotResponseHots, fluid)?.Message : historyHot.Message}}</td>
        <td class="col-md-1">{{moment.utc(historyHot.WeatherDateTime).format($root.timeFormat)}} {{historyHot.SuffixDate}}</td>
        <td class="col-md-1">{{historyHot.Temperature|formatTemperature:historyHot.ResponseType}}</td>
        <td class="col-md-2">{{historyHot.Weather}}</td>
    </tr>
</table>