<div class="deicingRecords noMargins">
    <page-title>{{('Deicing data'|translate)}}</page-title>
    <form #form #ngForm="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label *ngIf="serviceProviders.length > 1">
            <span class="caption">{{'Service provider'|translate}}</span>
            <multiselect name="query.ServiceProviderId" [(ngModel)]="query.ServiceProviderId">
                <multiselect-option *ngFor="let option of serviceProviders|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </label>
        <label *ngIf="airlines.length > 1">
            <span class="caption">{{'Airline'|translate}}</span>
            <multiselect name="query.AirlineId" [(ngModel)]="query.AirlineId">
                <multiselect-option *ngFor="let option of airlines|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </label>
        <label *ngIf="airports.length > 1">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect name="query.AirportId" [(ngModel)]="query.AirportId">
                <multiselect-option *ngFor="let option of airports|orderBy:'ICAOCode'" [value]="option.Id" [name]="option.ICAOCode + '/' + option.IATACode + ' - ' + option.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate" />
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate" />
        </label>
        <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="submit" [disabled]="!ngForm.form.valid || !query.FromDate || !hasRequiredFilters()"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" [disabled]="!ngForm.form.valid || !query.FromDate || !hasRequiredFilters()" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
        </label>
        <label class="inline" *ngIf="'DeicingExportAccountingReport'|hasPermission">
            <span class="caption">&nbsp;</span>
            <button type="button" [disabled]="!ngForm.form.valid || !query.FromDate || !hasRequiredFilters()" (click)="downloadAccountingReport()"><span class="mdi mdi-download"></span> {{'Accounting Report'|translate}}</button>
        </label>
        <label class="inline" *ngIf="'DeicingDeleteRecords'|hasPermission">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="deleteSelected()" [disabled]="!grid.gridApi || grid.gridApi.getSelectedRows().length === 0"><span class="mdi mdi-delete"></span> {{'Delete selected'|translate}}</button>
        </label>
        <label class="inline">
            <input type="checkbox" (change)="showColumnsInUtc()" [(ngModel)]="useUtc" name="useUtc" /> {{'Show Time in UTC'|translate}}
        </label>
        <label class="inline">
            <input type="checkbox" (change)="showColumnsInGallons()" [(ngModel)]="useGallons" name="useGallons" /> {{'Show volume in gallons'|translate}}
        </label>
        <label class="inline">
            <input type="checkbox" (change)="showColumnsInFahrenheit()" [(ngModel)]="useFahrenheit" name="useFahrenheit" /> {{'Show temperature in Fahrenheit'|translate}}
        </label>
    </form>

    <grid #grid
          gridId="preferences.DeicingRecord.ColumnExport_v13"
          [query]="getQuery()"
          [resource]="api.DeicingRecord"
          [exportResource]="api.DeicingRecordExport"
          [columnDefs]="columnDefs"
          [rowSelection]="'multiple'"
          [components]="components"
          [headerHeight]="96">
    </grid>
</div>
