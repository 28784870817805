<form #form #ngForm="ngForm" (submit)="save()">
	<page-title>{{('Airline'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="airlineEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewAirlineServiceProviders'|hasPermission)" (click)="switchTab('serviceProviders')" [class.active]="tab == 'serviceProviders'" [class.invalid]="!ngForm.form.controls.serviceProvidersForm?.valid">{{'Service providers'|translate}}</a>
			<a class="tab" *ngIf="('ConfigViewAirlineAirplanes'|hasPermission)" (click)="switchTab('airplanes')" [class.active]="tab == 'airplanes'" [class.invalid]="!ngForm.form.controls.airplanesForm?.valid || tailNumber != null">{{'Aircraft'|translate}}</a>
			<label>
				<input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine || tailNumber != null" value="{{'Save'|translate}}" />
			</label>
			<label>
				<input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
			</label>
			<label>
				<input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
			</label>
		</div>

		<fieldset [disabled]="!('ConfigEditAirlines'|hasPermission)" class="contentMargins form" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Name'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Name" name="Name" />
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="Active" />
			</label>
			<label>
				<span class="caption">{{'IATA code'|translate}}</span>
				<input type="text" required [(ngModel)]="item.IATACode" name="IATACode" pattern="^[A-Z0-9]{2,3}$" style="width: 50px;" />
			</label>
			<label>
				<span class="caption">{{'ICAO code'|translate}}</span>
				<input type="text" required [(ngModel)]="item.ICAOCode" name="ICAOCode" pattern="^[A-Z0-9]{2,3}$" maxlength="4" />
			</label>
			<label>
				<span class="caption">{{'Client for coordinator'|translate}}</span>
				<select [(ngModel)]="item.ClientId" name="item.ClientId">
					<option [ngValue]="null">{{'None'|translate}}</option>
					<option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
						{{client.Name}}
					</option>
				</select>
			</label>
			<div class="field">
				<span class="caption">{{'Logo'|translate}} <a *ngIf="item.CoordinatorLogo != null" (click)="confirm(translateService.translate('Are you sure?')) ? (item.CoordinatorLogo = null || ngForm.form.markAsDirty()) : false;" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a></span>
				<file-as-data-uri accept="image/*" [(ngModel)]="item.CoordinatorLogo" name="item.CoordinatorLogo" (ngModelChange)="logoChanged();"></file-as-data-uri>
				<br />
				<img *ngIf="item.CoordinatorLogo != null" [src]="item.CoordinatorLogo" />
			</div>
			<br />
			<h3>{{'Aircraft Identification'|translate}}</h3>
			<label>
				<span class="caption">{{'Prefix'|translate}}</span>
				<input type="text" [(ngModel)]="item.AircraftIdentificationPrefix" name="AircraftIdentificationPrefix" [required]="item.AircraftIdentificationUneditablePrefix" (ngModelChange)="onRegistrationPrefixChange()" /><a class="mdi mdi-eye" style="padding: 6px; color: dodgerblue;" title="{{'If there is more than one prefix, separate them by comma, for example : C-, G-, OO-'|translate}}"></a>
			</label>
			<label>
				<span class="caption">{{'Uneditable prefix'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.AircraftIdentificationUneditablePrefix" name="AircraftIdentificationUneditablePrefix" />
			</label>
			<label>
				<span class="caption">{{'Placeholder'|translate}}</span>
				<input type="text" [(ngModel)]="item.AircraftIdentificationPlaceholder" name="AircraftIdentificationPlaceholder" />
			</label>
			<label>
				<span class="caption">{{'Regex expression'|translate}}</span>
				<input type="text" [(ngModel)]="item.AircraftIdentificationRegexExpression" name="AircraftIdentificationRegexExpression" />
			</label>
		</fieldset>

		<div class="flexTab" *ngIf="serviceProvidersColumnDefs != null" [style.display]="tab == 'serviceProviders' ? '' :'none'">
			<div class="options contentMargins">
				<label class="inline search">
					<span class="mdi mdi-magnify"></span>
					<input [(ngModel)]="serviceProviderSearchQuery" [ngModelOptions]="{standalone: true}" name="serviceProviderSearchQuery" (ngModelChange)="serviceProvidersGrid.gridApi.setRowData(filter(transformDeicingAirlineServiceProviders(item.DeicingServiceProviderAirlines), serviceProviderSearchQuery));" />
				</label>
				<label class="inline">
					<input type="button" class="green" *ngIf="('ConfigEditAirlineServiceProviders'|hasPermission)" (click)="showServiceProvidersPopup();" value="{{'Add service providers...'|translate}}" />
				</label>
                <label class="inline">
                    <span class="caption">&nbsp;</span>
                    <button type="button" [disabled]="item.Id === 0" (click)="serviceProvidersGrid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
                </label>
			</div>

            <config-grid #serviceProvidersGrid
                         class="tabContent"
                         ngModelGroup="serviceProvidersForm"
                         #serviceProvidersForm="ngModelGroup"
                         gridId="preferences.AirlineServiceProvider.ColumnExport"
                         [query]="exportDeicingAirlineDeicingServiceProvidersQuery"
                         [exportResource]="exportDeicingAirlineDeicingServiceProvidersResource"
                         [columnDefs]="serviceProvidersColumnDefs"
                         [rowData]="transformDeicingAirlineServiceProviders(item.DeicingServiceProviderAirlines)">
            </config-grid>
		</div>

		<div class="flexTab" [style.display]="tab == 'airplanes' ? '' :'none'">
			<div class="options contentMargins">
				<label class="inline search">
					<span class="mdi mdi-magnify"></span>
					<input [(ngModel)]="airplaneSearchQuery" [ngModelOptions]="{standalone: true}" name="airplaneSearchQuery" />
				</label>
				<label>
					<select *ngIf="registrationPrefixes.length > 1 || item.AircraftIdentificationUneditablePrefix" [(ngModel)]="currentRegistrationPrefix" [name]="regPrefix" (ngModelChange)="onRegistrationPrefixChange()" [ngModelOptions]="{standalone: true}">
						<option *ngFor="let option of registrationPrefixes" [ngValue]="option">
							{{option}}
						</option>
					</select>
					<input type="text" [(ngModel)]="tailNumber" [ngModelOptions]="{standalone: true}" name="tailNumber" [placeholder]="item.AircraftIdentificationPlaceholder || ''" [pattern]="getPattern()" (keyup)="checkRegistrationFormat($event)" (click)="onRegistrationClick($event)" />
					<input type="button" class="green" *ngIf="('ConfigEditAirlineAirplanes'|hasPermission)" (click)="addAirplane(tailNumber); tailNumber = null;" value="{{'Add aircraft'|translate}}" [disabled]="tailNumber == null" />
				</label>
			</div>
			<fieldset [disabled]="!('ConfigEditAirlineAirplanes'|hasPermission)" class="tabContent form" ngModelGroup="airplanesForm">
				<table class="table">
					<tr>
						<th order-by-field="orderByFieldAirplanes" order-by-reverse="orderByReverseAirplanes" sortable-column="airplane.TailNumber">{{'Registration number'|translate}}</th>
						<th></th>
					</tr>
					<tr *ngFor="let airplane of searchFilter(item.Airplanes, airplaneSearchQuery, 'airplanes')|applySort:orderByFieldAirplanes:orderByReverseAirplanes:this:'airplanes'">
						<td>{{airplane.TailNumber}}</td>
						<td>
							<a *ngIf="('ConfigEditAirlineAirplanes'|hasPermission)" (click)="removeAirplane(airplane);" class="mdi mdi-delete"></a>
						</td>
					</tr>
				</table>
			</fieldset>
		</div>
	</div>
</form>
