<table class="table table-striped table-hover hotSize">
    <tr *ngFor="let fluid of fluids">
        <td class="col-md-6">{{fluid.ClientFluidName + (fluid.FluidTypeNumber === 1 ? '' : (' ' + fluid.Dilution)) + ' (Type ' + fluid.FluidTypeNumber + ')'}}</td>
        <td class="info col-md-6"><strong>{{latestHot.HotResponseHots?.length > 0 ? findHot(latestHot.HotResponseHots, fluid)?.Message : latestHot.Message}}</strong></td>
    </tr>
    <tr>
        <td class="col-md-6">{{'Date'|translate}}</td>
        <td class="col-md-6">{{moment.utc(latestHot.Datetime).format($root.dateFormat)}}</td>
    </tr>
    <tr>
        <td class="col-md-6">{{latestHot.Type}} {{'Timestamp'|translate}}</td>
        <td class="col-md-6">{{moment.utc(latestHot.Datetime).format($root.timeFormat)}} {{latestHot.SuffixDate}}</td>
    </tr>
    <tr>
        <td class="col-md-6">{{'Temperature'|translate}}</td>
        <td class="col-md-6">{{latestHot.Temperature|formatTemperature:latestHot.Type}}</td>
    </tr>
    <tr>
        <td class="col-md-6">{{'Weather'|translate}}</td>
        <td class="col-md-6">{{latestHot.Weather}}</td>
    </tr>
</table>