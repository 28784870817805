<ul>
	<li *ngIf="'Coordinator'|hasPermission">
		<ul>
			<li><a routerLink="/coordinator" routerLinkActive="active" *ngIf="'Coordinator'|hasPermission">{{'Coordinator'|translate}}</a></li>
		</ul>
	</li>
	<li *ngIf="('DataDeicing'|hasPermission) || ('DataAirlineDeicing'|hasPermission) || ('DataFlightOpsDeicing'|hasPermission)">
		<h3>{{'Deicing'|translate}}</h3>
		<ul>
			<li><a routerLink="/deicingRecords/new" routerLinkActive="active" *ngIf="'DeicingAddRecord'|hasPermission">{{'Add record'|translate}}</a></li>
			<li><a routerLink="/deicingRecordsData" routerLinkActive="active" *ngIf="'DataDeicing'|hasPermission">{{'View data'|translate}}</a></li>
			<li><a routerLink="/airlineDeicingRecordsData" routerLinkActive="active" *ngIf="'DataAirlineDeicing'|hasPermission">{{'View data'|translate}}<span *ngIf="'DataDeicing'|hasPermission"> (airline view)</span></a></li>
			<li><a routerLink="/flightOpsDeicingRecordsData" routerLinkActive="active" *ngIf="'DataFlightOpsDeicing'|hasPermission">{{'View data'|translate}}<span *ngIf="'DataDeicing'|hasPermission"> (FlightOps view)</span></a></li>
			<li><a routerLink="/deicingRecordsUpload" routerLinkActive="active" *ngIf="'DeicingRecordUpload'|hasPermission">{{'Upload'|translate}}</a></li>
			<li><a routerLink="/truckInfo" routerLinkActive="active" *ngIf="'DataDeicingTruckInspections' | hasPermission">{{'Truck Inspections' | translate}}</a></li>
			<li><a routerLink="/deicingRecordImportLog" routerLinkActive="active" *ngIf="'DeicingRecordImportLog' | hasPermission">{{'Deicing Record Import Log' | translate}}</a></li>
            <li><a routerLink="/deicingRecordUploadLog" routerLinkActive="active" *ngIf="'DeicingRecordUploadLog' | hasPermission">{{'Deicing Record Upload Log' | translate}}</a></li>
			<li><a routerLink="/loginHistory" routerLinkActive="active" *ngIf="'DataLogins' | hasPermission">Login History</a></li>

		</ul>
	</li>
	<li *ngIf="currentUser?.Permissions.indexOf('Config') > -1">
		<h3>{{'Configuration'|translate}}</h3>
		<ul>
			<li><a routerLink="/serviceProviders" routerLinkActive="active" *ngIf="'ConfigViewServiceProviders'|hasPermission">{{'Service providers'|translate}}</a></li>
			<li><a routerLink="/deicingFormConfigurations" routerLinkActive="active" *ngIf="'ConfigViewDeicingFormConfigurations'|hasPermission">{{'Deicing form configuration'|translate}}</a></li>
			<li><a routerLink="/users" routerLinkActive="active" *ngIf="'ConfigViewUsers'|hasPermission">{{'Users'|translate}}</a></li>
			<li><a routerLink="/airlines" routerLinkActive="active" *ngIf="'ConfigViewAirlines'|hasPermission">{{'Airlines'|translate}}</a></li>
			<li><a routerLink="/deicingFluids" routerLinkActive="active" *ngIf="'ConfigViewDeicingFluids'|hasPermission">{{'Deicing fluids'|translate}}</a></li>
			<li><a routerLink="/changes" routerLinkActive="active" *ngIf="'DataChanges'|hasPermission">{{'Changes'|translate}}</a></li>
			<li><a routerLink="/deicingMobileConfigurations" routerLinkActive="active" *ngIf="'ConfigViewDeicingMobileConfigurations'|hasPermission">{{'Deicing mobile configuration'|translate}}</a></li>
        </ul>
	</li>
</ul>