import { Component, ViewChild } from '@angular/core';
import { ApiService } from "../shared/Api.service";
import { FileDownloadService, GridComponent, ValueFormatters } from 'swx.front-end-lib';
import { RawDataCellRendererComponent } from "../shared/cellRenderers/RawDataCellRenderer.component";

@Component({
    templateUrl: './deicingRecordImportLog.component.html'
})
export class DeicingRecordImportLogComponent {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    query: any = {};
    airports = this.api.Airport.query();
    selectedIds: number[] = [];
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
    };

    columnDefs = [
        { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: 'left' },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
        { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left' },
        {
            headerName: 'ContentType',
            field: 'ContentType',
            colId: 'ContentType',
        },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 100 },
        {
            headerName: 'Destination',
            field: 'Destination',
            colId: 'Destination',
        },
        {
            headerName: 'Errors',
            field: 'Errors',
            colId: 'Errors',
        },
        {
            headerName: 'Filename',
            field: 'Filename',
            colId: 'Filename',
        },
        {
            headerName: 'Format',
            field: 'Format',
            colId: 'Format',
        },
        {
            headerName: 'IP Address',
            field: 'IPAddress',
            colId: 'IPAddress',
        },
        {
            headerName: 'Message Id',
            field: 'MessageId',
            colId: 'MessageId',
        },
        {
            headerName: 'Raw Data',
            field: 'RawData',
            colId: 'RawData',
            cellRenderer: RawDataCellRendererComponent,
        },
        {
            headerName: 'SQS queue configuration',
            field: 'SQSQueueConfigurationName',
            colId: 'SQSQueueConfigurationName',
        },
        {
            headerName: 'Source',
            field: 'Source',
            colId: 'Source',
        },
        {
            headerName: 'User Agent',
            field: 'UserAgent',
            colId: 'UserAgent',
        },
    ];

    refresh() {
        window.localStorage['deicingRecordImportQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    resetQuery() {
        this.query = {
            FromDate: null,
            ToDate: null,
            Filters: [],
            AirportId: []
        };
    }

    constructor(
        public api: ApiService,
        private fileDownloadService: FileDownloadService,
        private formatters: ValueFormatters,
    ) {
        if (window.localStorage['deicingRecordImportQuery']) {
            this.query = JSON.parse(window.localStorage['deicingRecordImportQuery']);
        } else {
            this.resetQuery();
        }
    }
}
