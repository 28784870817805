import { Injectable } from '@angular/core';
import { IDeicingFormField } from './IDeicingFormField.model';

@Injectable({ providedIn: 'root' })
export class DeicingFormConfigurationService {
    private _availableDeicingFormFields: IDeicingFormField[] = [
        {
            Id: 'Identifier',
            Name: 'Identifier',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'ServiceProviderId',
            Name: 'Service provider',
            Visible: true,
            Required: true,
            Optional: false,
        },
        {
            Id: 'AirlineId',
            Name: 'Airline',
            Visible: true,
            Required: true,
            Optional: false,
        },
        {
            Id: 'AirportId',
            Name: 'Airport',
            Visible: true,
            Required: true,
            Optional: false,
        },
        {
            Id: 'FlightNumber',
            Name: 'Flight number',
            Visible: true,
            Required: true,
            Optional: false,
        },
        {
            Id: 'RegistrationNumber',
            Name: 'Registration number',
            Visible: true,
            Required: true,
            Optional: true,
            Autocomplete: true,
        },
        {
            Id: 'TailNumber',
            Name: 'Tail number',
            Visible: false,
            Required: false,
            Optional: true,
            Autocomplete: true,
        },
        {
            Id: 'EquipmentTypeIdentifier',
            Name: 'Equipment type',
            Visible: true,
            Required: true,
            Optional: false,
        },
        {
            Id: 'ConfirmNozzleTemperature',
            Name: 'Require nozzle temperature check',
            Visible: false,
            Required: true,
            Optional: true,
        },
        {
            Id: 'DeicingStartTime',
            Name: 'Deicing start time',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'HoldoverStartTime',
            Name: 'Holdover/Anti-ice start time',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'DeicingCompleteTime',
            Name: 'De/Anti-ice Finish Time',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'WheelsUpTime',
            Name: 'Wheels up time',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'ScheduledDepartureTime',
            Name: 'Scheduled departure time',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'OffBlockTime',
            Name: 'Off block time',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'DeicingLocation',
            Name: 'Deicing location',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'AircraftCondition',
            Name: 'Aircraft condition',
            Visible: true,
            Required: false,
            Optional: false,
            Multiselect: true
        },
        {
            Id: 'GateLocation',
            Name: 'Gate location',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Destination',
            Name: 'Destination',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'SprayCount',
            Name: 'Spray count',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'DeicingServiceProviderFluidId',
            Name: 'Deicing fluid',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Type1Brix',
            Name: 'Deicing fluid Brix',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'Type1MixPercent',
            Name: 'Deicing fluid mixture ratio %',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Type1Volume',
            Name: 'Deicing fluid volume',
            Visible: true,
            Required: false,
            Optional: true,
            UseGallons: false
        },
        {
            Id: 'AntiIcingServiceProviderFluidId',
            Name: 'Anti-icing fluid',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Type4Brix',
            Name: 'Anti-icing fluid Brix',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'Type4MixPercent',
            Name: 'Anti-icing fluid mixture ratio %',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Type4Volume',
            Name: 'Anti-icing fluid volume',
            Visible: true,
            Required: false,
            Optional: true,
            UseGallons: false
        },
        {
            Id: 'PassengerLoad',
            Name: 'Passenger load',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'DepartureRunway',
            Name: 'Departure runway',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Remarks',
            Name: 'Remarks',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Sections',
            Name: 'Sections',
            Visible: true,
            Required: false,
            Optional: true,
            Multiselect: true,
        },
        {
            Id: 'AircraftSides',
            Name: 'Sides',
            Visible: true,
            Required: false,
            Optional: true,
            Multiselect: true,
        },
        {
            Id: 'OAT',
            Name: 'OAT',
            Visible: true,
            Required: false,
            Optional: true,
            UseFahrenheit: false
        },
        {
            Id: 'PrecipitationType',
            Name: 'Precipitation type',
            Visible: true,
            Required: false,
            Optional: true,
            Multiselect: true,
        },
        {
            Id: 'CrewNumber',
            Name: 'Crew number',
            Visible: true,
            Required: false,
            Optional: true,
        },
        {
            Id: 'ManualMethods',
            Name: 'Manual methods',
            Visible: true,
            Required: false,
            Optional: true,
            Multiselect: true
        },
        {
            Id: 'Treatment1',
            Name: 'Treatment 1',
            Visible: false,
            Required: false,
            Optional: true,
        },
        {
            Id: 'Treatment2',
            Name: 'Treatment 2',
            Visible: false,
            Required: false,
            Optional: true,
        },
        {
            Id: 'TruckNumber',
            Name: 'Truck Number',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'TruckDriver',
            Name: 'Truck Driver',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'TruckOperator',
            Name: 'Truck Operator',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'Steps',
            Name: 'Number of steps',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'IsHOTDeicing',
            Name: 'HOT required?',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'CanBeCancelled',
            Name: 'Can be cancelled?',
            Visible: false,
            Required: false,
            Optional: true
        },
        {
            Id: 'CanBeModified',
            Name: 'Can be modified?',
            Visible: false,
            Required: false,
            Optional: true
        }
    ];

    get availableDeicingFormFields() {
        return this._availableDeicingFormFields;
    }
}
