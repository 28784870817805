import {Component, OnInit, ElementRef, ViewChild, ViewContainerRef} from '@angular/core';
import moment from 'moment';
import jQuery from 'jquery';
import {
    ApiService,
    Fluid,
    CoordinatorAirline,
    CoordinatorAirport,
    CoordinatorClientAirport,
    HotResponse,
    PortalMobileClientConfiguration,
    MobileWeatherInfo, SnowVisibilitiesMenuValue, SnowVisibilitiesMenuIntensity
} from '../shared/Api.service';
import { RootScope } from '../shared/RootScope.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import { HotBuilderService } from '../shared/HotBuilder.service';
import { DialogService, IResourceItemList, GridComponent, QuerySerializerService, IResourceItemMap } from 'swx.front-end-lib';
import { FluidSortPipe } from '../shared/FluidSort.pipe';
import { WeatherTypeDialogComponent } from './WeatherTypeDialog.component';
import { IntensityDialogComponent } from './IntensityDialog.component';
import {GraphHots} from "../hotGraph/HotGraph.component";

var unaryCompare = (f: Function) => (v1, v2) => f(v1) < f(v2) ? -1 : f(v1) > f(v2) ? 1 : 0;

@Component({
    templateUrl: 'Coordinator.component.html'
})
export class CoordinatorComponent implements OnInit {
    moment = moment;
    fluids: IResourceItemList<Fluid>;
    airlines: IResourceItemList<CoordinatorAirline>;
    airports: IResourceItemList<CoordinatorAirport>;
    clientAirports: IResourceItemList<CoordinatorClientAirport>;
    selectedAirport: CoordinatorAirport;
    selectedAirline: CoordinatorAirline;
    selectedClientAirport: CoordinatorClientAirport;
    selectedClientAirportFluids: Array<Fluid>;
    latestHot: any = null;
    graphHots: IResourceItemMap<GraphHots>;
    graphHotsCount: number;
    hotResponse: HotResponse;
    PortalHotGraphDuration = 120;
    PortalHotGraphAveragingPeriod = 30;
    maxHOTConfig = 120;
    graphOptions = {
        legend: {
            position: 'none'
        },
        height: 600,
        chartArea: {
            width: '90%',
            bottom: 100,
            left: 100,
            right: 0,
            top: 20,
        },
        backgroundColor: {}
    };
    deicingRequests: any;
    
    hotRequests: any;
    hotRequestQuery = {
        HotRequestType: []
    };
    hotRequestTypes = {
        "API": "API",
        "Portal": "Portal",
        "ACARS": "ACARS",
        "MobileAPI": "MOBILE API",
        "XML": "XML",
    };

    manualEntryMode: boolean;
    mobileClientConfiguration: PortalMobileClientConfiguration;
    selectedWeatherMenuItem?: MobileWeatherInfo;
    selectedVisibility?: SnowVisibilitiesMenuValue;
    selectedIntensity?: SnowVisibilitiesMenuIntensity;
    temperature: number = null;
    minTemp: number;

    @ViewChild('deicingRequestGrid', { static: true }) deicingRequestGrid: GridComponent;
    deicingRequestColumnDefs = [
        {
            "headerName": "Deicing requests",
            "children": [
                { "colId": "DeicingRequest.CreatedDate", "field": "RequestDateTime", "headerName": "Date (" + (this.$root.currentUser.UseLocalTime ? "airport local" : "UTC") + ")", "valueFormatter": c => c.value ? (this.$root.currentUser.UseLocalTime ? this.toAirportLocalTimeZone(c, this.$root.dateFormat) : moment.utc(c.value).format(this.$root.dateFormat)) : "", "width": 120, "pinned": "left", "filterType": "date" },
                { "colId": "DeicingRequest.CreatedTime", "field": "RequestDateTime", "headerName": "Time (" + (this.$root.currentUser.UseLocalTime ? "airport local" : "UTC") + ")", "width": 110, "valueFormatter": c => c.value ? ((this.$root.currentUser.UseLocalTime ? this.toAirportLocalTimeZone(c, this.$root.timeFormat) : moment.utc(c.value).format(this.$root.timeFormat)) + " " + c.data.SuffixDate) : "", "filterType": "date" },
                { "colId": "DeicingRequest.Id", "field": "DeicingRequest.Id", "headerName": "#", "width": 60 },
                { "colId": "DeicingResponse.Status", "field": "DeicingResponse.Status", "headerName": "Status", "width": 100 },
                { "colId": "DeicingResponse.ProcessedDate", "field": "ProcessedDateTime", "headerName": "Processed date (" + (this.$root.currentUser.UseLocalTime ? "airport local" : "UTC") + ")", "valueFormatter": c => c.value ? (this.$root.currentUser.UseLocalTime ? this.toAirportLocalTimeZone(c, this.$root.dateFormat) : moment.utc(c.value).format(this.$root.dateFormat)) : "", "width": 110, "filterType": "date" },
                { "colId": "DeicingResponse.ProcessedTime", "field": "ProcessedDateTime", "headerName": "Processed time (" + (this.$root.currentUser.UseLocalTime ? "airport local" : "UTC") + ")", "width": 110, "valueFormatter": c => c.value ? ((this.$root.currentUser.UseLocalTime ? this.toAirportLocalTimeZone(c, this.$root.timeFormat) : moment.utc(c.value).format(this.$root.timeFormat)) + " " + c.data.SuffixDate) : "", "filterType": "date" },
                { "colId": "DeicingRequest.Acreg", "field": "DeicingRequest.Acreg", "headerName": "Registration number", "width": 160 },
                { "colId": "FlightIdentifier", "field": "FlightIdentifier", "headerName": "Call sign", "width": 160 },
                { "colId": "DeicingRequest.Types", "field": "DeicingRequest.Types", "headerName": "Request Type", "width": 160 },
            ]
        }
    ];
    
    constructor(
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        public api: ApiService,
        public $root: RootScope,
        private hasPermissionService: HasPermissionService,
        private dialogService: DialogService,
        private querySerializer: QuerySerializerService,
    ) {
    }

    ngOnInit(): void {
        this.fluids = this.api.CoordinatorFluid.query();
        this.airlines = this.api.CoordinatorAirline.query();
        this.airports = this.api.CoordinatorAirport.query();

        Promise.all([this.airlines.$promise, this.airports.$promise]).then(() => {
	        if (this.airports.length === 1) {
		        this.selectAirport(this.airports[0]);
	        }

	        if (this.airlines.length === 1) {
		        this.selectAirline(this.airlines[0]);
	        }
        });

        var topPane = jQuery(this.elementRef.nativeElement).find('.topPane');
        var bottomPane = jQuery(this.elementRef.nativeElement).find('.bottomPane');
        var horizontalBorder = jQuery(this.elementRef.nativeElement).find('.horizontalBorder');
        
        var coordinatorHeight = Math.min(window.innerWidth - 4, parseInt(localStorage.getItem('coordinatorHeight') ?? '400'));

        var applyHeight = (top) => {
            topPane.css({ height: top });
            bottomPane.css({ top: top + 4 });
            localStorage.setItem('coordinatorHeight', top);
            coordinatorHeight = top;
        }

        if (coordinatorHeight) {
            applyHeight(coordinatorHeight);
            horizontalBorder.css({ top: coordinatorHeight });
        }

        horizontalBorder.draggable({
            axis: 'y',
            drag: (e, ui) => {
                ui.position.top = Math.min(window.innerHeight - 4, ui.position.top);
                applyHeight(ui.position.top);
                jQuery(window).trigger('resize');
            }
        });
        
        var resizeTimer;
        jQuery(window).resize(() => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                if (coordinatorHeight && coordinatorHeight > window.innerHeight) {
                    coordinatorHeight = window.innerHeight - 20;
                    horizontalBorder.css({ top: coordinatorHeight });
                    applyHeight(coordinatorHeight);
                }
            }, 250);
        });

        var leftPane = jQuery(this.elementRef.nativeElement).find('.leftPane');
        var rightPane = jQuery(this.elementRef.nativeElement).find('.rightPane');
        var verticalBorder = jQuery(this.elementRef.nativeElement).find('.verticalBorder');
        
        var coordinatorWidth = Math.min(window.innerWidth - 4, parseInt(localStorage.getItem('coordinatorWidth') ?? '400'));

        var applyWidth = (left) => {
            leftPane.css({ width: left });
            rightPane.css({ left: left + 4 });
            localStorage.setItem('coordinatorWidth', left);
            coordinatorWidth = left;
        }

        if (coordinatorWidth) {
            applyWidth(coordinatorWidth);
            verticalBorder.css({ left: coordinatorWidth });
        }

        verticalBorder.draggable({
            axis: 'x',
            drag: (e, ui) => {
                ui.position.left = Math.min(window.innerWidth - 4, ui.position.left);
                applyWidth(ui.position.left);
            }
        });

        jQuery(window).resize(() => {
            if (coordinatorWidth && coordinatorWidth > window.innerWidth) {
                coordinatorWidth = window.innerWidth - 20;
                verticalBorder.css({ left: coordinatorWidth });
                applyWidth(coordinatorWidth);
            }
        });
    }

    toAirportLocalTimeZone(c, format) {
        if (!c.value) return "";
        var airport = this.airports.find(a => a.Id === c.node.data.DeicingRequest.AirportId);
        if (!airport || !airport.TimeZone) return "";
        return c.value
            ? moment.utc(c.value)['tz'](airport.TimeZone).format(format)
            : "";
    }

    selectAirline(airline) {
        this.manualEntryMode = false;
        this.selectedAirline = airline;
        this.refreshClientAirports();

        this.mobileClientConfiguration = this.api.CoordinatorMobileClient.get(this.selectedAirline.Id) as PortalMobileClientConfiguration;

        this.deicingRequestGrid?.refresh();
    }

    selectAirport(airport) {
        this.manualEntryMode = false;
        this.selectedAirport = airport;
        if (this.selectedAirline) {
	        this.refreshClientAirports();
        }

        this.deicingRequestGrid?.refresh();
    }

    manualRefresh() {
        this.manualEntryMode = false;
        this.refresh();
    }

    refresh() {
        this.refreshClientAirports().then(() => {
            this.deicingRequestGrid?.refresh();
        });
    }

    refreshClientAirports() {
	    var clientAirports = this.api.CoordinatorClientAirport.query({ AirlineId: this.selectedAirline.Id });
        return Promise.all([clientAirports.$promise, this.fluids.$promise]).then(() => {
            clientAirports.forEach(a => {
                var hasHots = false;
                var hasHotMessages = false;

                try {
                    a.HotResponse.HotResponseHots.forEach(hrh => {
                        var hotDetails = HotBuilderService.parseHot(hrh.Message);

                        hasHotMessages = hasHotMessages || !!hotDetails.Message;
                        hasHots = hasHots || hotDetails.MinHot > 0;

                        var fluid = this.fluids.find(f => f.Id === hrh.FluidId);
                        hrh.FluidTypeNumber = fluid.FluidTypeNumber;
                        hrh.MinHot = hotDetails.MinHot;
                        hrh.MaxHot = hotDetails.MaxHot;
                        hrh.NonHotMessage = hotDetails.Message;
                        hrh.IsGeneric = fluid.Name.toUpperCase().indexOf("GENERIC") !== -1;
                        hrh.IsComposite = fluid.WingMaterialType === 'Composite';
                        hrh.SortableDilution = hrh.FluidType === 'Type1' || !hrh.FluidDilution || hrh.FluidDilution.indexOf("/") === -1
                            ? 0
                            : parseInt(hrh.FluidDilution.substr(0, hrh.FluidDilution.indexOf("/")));
                    });

                    a.IsFreezing = a.HotResponse.Temperature < 1;
                    a.IsClear = (a.HotResponse.Type !== 'LWE'
                            && (a.WeatherTypeNameResult === 'CLR'
                                || a.WeatherTypeNameResult === 'OBS'
                                || a.WeatherTypeNameResult === 'OTHN'))
                        || a.HotResponse.WeatherType === 'CLR';

                    a.Type1Hot = a.HotResponse.HotResponseHots
                        .filter(hrh => hrh.FluidType === 'Type1')
                        .sort(unaryCompare(hrh => hrh.IsGeneric
                            ? (hrh.IsComposite ? 3 : 2)
                            : (hrh.IsComposite ? 1 : 0)))[0];

                    a.Type2Hot = a.HotResponse.HotResponseHots
                        .filter(hrh => hrh.FluidType === 'Type2')
                        .sort(unaryCompare(hrh => hrh.IsGeneric
                            ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                            : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];

                    a.Type3Hot = a.HotResponse.HotResponseHots
                        .filter(hrh => hrh.FluidType === 'Type3')
                        .sort(unaryCompare(hrh => hrh.IsGeneric
                            ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                            : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];

                    a.Type4Hot = a.HotResponse.HotResponseHots
                        .filter(hrh => hrh.FluidType === 'Type4')
                        .sort(unaryCompare(hrh => hrh.IsGeneric
                            ? (hrh.FluidDilution === "100/0" ? 2 : 3)
                            : (hrh.FluidDilution === "100/0" ? 0 : 1)))[0];
                } catch (e) {
                    console.log(e);
                }
            });

            if (this.selectedAirport != null && this.selectedAirline != null && !this.manualEntryMode) {
	            this.selectedClientAirport = clientAirports.find(ca => ca.AirportId === this.selectedAirport.Id
		            && ca.ClientId === this.selectedAirline.ClientId);
	            if (this.selectedClientAirport) this.refreshSelectedClientAirport();
            }

            this.clientAirports = clientAirports;

            return clientAirports;
        });
    }

    refreshSelectedClientAirport() {
        this.graphHots = null;
        this.selectedClientAirportFluids = null;

        this.hotResponse = JSON.parse(JSON.stringify(this.selectedClientAirport.HotResponse));
        var maxHot = Math.max.apply(null, this.hotResponse.HotResponseHots
            .map(h => h.MaxHot > h.MinHot ? h.MaxHot : h.MinHot));

        this.maxHOTConfig = maxHot === 0 ? 120 : maxHot;

        var fluidsPromise = this.fluids.$promise.then(() => {
            return this.selectedClientAirportFluids = FluidSortPipe.sort(this.fluids.filter(f => f.ClientId === this.selectedAirline.ClientId && f.Airports.some(a => a === this.selectedAirport.Id as any)));
        });

        if (this.hasPermissionService.hasPermission('CoordinatorHotGraph')) {
            this.graphHotsCount = this.PortalHotGraphDuration / this.PortalHotGraphAveragingPeriod;
            
            fluidsPromise.then(() => {
                this.graphHots = this.api.CoordinatorHotGraphHot.queryMap<GraphHots>({
                    ClientId: this.selectedAirline.ClientId,
                    AirportId: this.selectedAirport.Id,
                    Count: this.graphHotsCount,
                    Interval: this.PortalHotGraphAveragingPeriod,
                    PortalUserId: this.$root.currentUser.UserId,
                });
            });
        }
        fluidsPromise.then(() => {
            this.hotRequestQueryChanged();
        });
    }

    hotRequestQueryChanged() {
        if (this.hasPermissionService.hasPermission('CoordinatorHotRequestHistory')) {
            this.hotRequests = this.api.CoordinatorHotRequest.query(this.querySerializer.toHttpParams(Object.assign(this.hotRequestQuery, {
                ClientId: this.selectedAirline.ClientId,
                AirportId: this.selectedAirport.Id,
            })));
        }
    }

    isEmpty(value) {
        return value === null || typeof value === 'undefined' || value === '';
    }

    getHotStyle(hot): any {
        var targetLeftPercent = 50; // hack
        var targetRightPercent = 80; // to review
        var max = Math.max.apply(null,[hot.MinHot, hot.MaxHot]);
        var useStyle = Math.floor((max * 100) / this.maxHOTConfig) >= targetLeftPercent;

        var style = { min: {}, max: {}, separator: " - " };
        var leftWidth = Math.floor((hot.MinHot * 100) / this.maxHOTConfig);
        var rightWidth = (Math.floor((hot.MaxHot * 100) / this.maxHOTConfig)) - leftWidth;

        if (useStyle) {
            style.separator = null;

            style.min = {
                'display': 'inline-block',
                'width': (leftWidth - (leftWidth > targetRightPercent && hot.MaxHot ? leftWidth - targetRightPercent : 0)), 
                'text-align': 'right',
                'float':'left'
            },
            style.max = {
                'display': 'inline-block',
                'width': (rightWidth + (leftWidth > targetRightPercent && hot.MaxHot ? leftWidth - targetRightPercent : 0)), 
                'text-align': 'right',
                'float':'left'
            }
        }

        return style;
    }

    formatFluidType(fluidType) {
        if (typeof fluidType === 'string' && fluidType.toLowerCase().indexOf('type') === 0) {
            fluidType = parseInt(fluidType.substring(4));
        }

        return fluidType;
    }
    
    goOffline() {
        this.manualEntryMode = true;

        this.clearOfflineHot();

        this.selectedIntensity = null;
        this.selectedVisibility = null;
        this.selectedWeatherMenuItem = null;

        var temperature = this.hotResponse.Temperature;
        this.minTemp = -49;
        if (this.$root.currentUser.UseFahrenheit) {
            temperature = this.$root.celsiusToFahrenheit(temperature);
            this.minTemp = Math.floor(this.$root.celsiusToFahrenheit(this.minTemp));
        }

        this.temperature = Math.floor(temperature);
    }

    editWeatherType() {
        this.goOffline();
    }

    clearOfflineHot() {
        this.hotResponse.HotResponseHots.forEach(hrh => {
            hrh.MinHot = null;
            hrh.MaxHot = null;
            hrh.NonHotMessage = null;
        });
    }

    private getSelectedMetarWeatherType() {
        return this.selectedWeatherMenuItem?.MetarWeatherTypeId
            ?? this.selectedVisibility?.MetarWeatherType
            ?? this.selectedIntensity?.MetarWeatherType;
    }

    getManualHotResponse() {
        this.clearOfflineHot();

        var selectedMetarWeatherType = this.getSelectedMetarWeatherType();

        if (selectedMetarWeatherType != null && this.manualEntryMode) {
            this.hotResponse.HotResponseHots.forEach(hrh => {
                var clientFluid = this.mobileClientConfiguration.MobileClient.ClientFluids.find(cf => cf.FluidId === hrh.FluidId);

                var temperature = this.temperature;
                if (this.$root.currentUser.UseFahrenheit) {
                    temperature = this.$root.fahrenheitToCelsius(temperature);
                }

                var offlineHot = HotBuilderService.getManualHot(
                    this.mobileClientConfiguration.MobileClient,
                    selectedMetarWeatherType,
                    temperature,
                    clientFluid,
                    this.mobileClientConfiguration.FluidSpecs,
                    this.mobileClientConfiguration.MessageMappings,
                );

                hrh.MinHot = offlineHot.MinHot;
                hrh.MaxHot = offlineHot.MaxHot;
                hrh.NonHotMessage = offlineHot.holdoverTime;
            });

            var maxHot = Math.max.apply(null, this.hotResponse.HotResponseHots
                .map(h => h.MaxHot > h.MinHot ? h.MaxHot : h.MinHot));

            this.maxHOTConfig = maxHot === 0 ? 120 : maxHot;
        }
    }

    showWeatherTypePopup() {
        this.mobileClientConfiguration.$promise.then(() => {
            this.dialogService.show(this.viewContainerRef, WeatherTypeDialogComponent,
                {
                    selectedWeatherMenuItem: this.selectedWeatherMenuItem,
                    weatherTypes: this.mobileClientConfiguration.Weathers,
                    close: weatherMenuItem => {
                        if (weatherMenuItem !== null) {
                            this.selectedIntensity = null;
                            this.selectedVisibility = null;
                            this.selectedWeatherMenuItem = weatherMenuItem;
                            this.getManualHotResponse();
                        }
                        return weatherMenuItem;
                    }
                },
                {
                    title: 'Select Precipitation',
                    width: 400,
                    height: 600,
                    modal: true,
                    dialogClass: 'weatherTypePopup'
                });
        });
    }

    showIntensityPopup() {
        var selectedTemperatureOption = this.selectedWeatherMenuItem.SnowVisibilityMenu.TemperatureOptions
            .filter(x => this.temperature <= x.MaxTempC)
            .sort((a, b) => a.MaxTempC > b.MaxTempC ? 1 : -1)[0];

        this.dialogService.show(this.viewContainerRef, IntensityDialogComponent,
            {
                selectedIntensity: this.selectedIntensity,
                selectedVisibility: this.selectedVisibility,
                selectedTemperatureOption: selectedTemperatureOption,
                selectedDayNightOption: selectedTemperatureOption.DayNightOptions[0],
                snowVisibilities: this.selectedWeatherMenuItem.SnowVisibilityMenu,
                close: result => {
                    if (result != null) {
                        this.selectedVisibility = result.visibility;
                        this.selectedIntensity = result.intensity;
                        this.getManualHotResponse();
                    }
                }
            },
            {
                title: 'Select intensity',
                width: 400,
                height: 300,
                modal: true,
                dialogClass: 'intensityPopup'
            });
    }
}
