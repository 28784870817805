import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, NotificationService, BusyIndicatorService } from 'swx.front-end-lib';

@Component({
    templateUrl: 'Login.component.html'
})
export class LoginComponent {
    username: string;
    password: string;
    loginAttempted = false;

    constructor(
        private authentication: AuthenticationService,
        private notification: NotificationService,
        private busyIndicator: BusyIndicatorService,
    ) {
    }

    login() {
        this.loginAttempted = true;

        if (this.username == null || this.password == null) {
            return;
        }

        this.busyIndicator.setBusy(true);
        this.authentication.setCredentials(this.username, this.password)
            .then(() => {
                this.busyIndicator.setBusy(false);
            }, error => {
                this.busyIndicator.setBusy(false);
                if (error.status === 400) {
                    this.notification.show('Sorry, that login/password does not match our records.', { type: 'error', sticky: true });
                } else {
                    this.notification.show(error.statusText, { type: 'error', sticky: true });
                }
            });
    }
}
